import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  ISO8601DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
};

export enum AdminReservationOrderField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  PeopleCount = 'PEOPLE_COUNT'
}

export type AdminReservationsOrderBy = {
  direction: OrderDirection;
  field: AdminReservationOrderField;
};

export enum AdminVoucherSalesOrder {
  Recent = 'RECENT'
}

export type Agreement = {
  __typename?: 'Agreement';
  currentTenant: Scalars['String']['output'];
  festivalEditionRestaurant: FestivalEditionRestaurant;
  id: Scalars['ID']['output'];
  metadatas: Array<Metadata>;
  restaurant: Reservable;
  title: Scalars['String']['output'];
  type: DocumentTypeEnum;
};

/** Autogenerated return type of AppleSignIn */
export type AppleSignInPayload = {
  __typename?: 'AppleSignInPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Area = {
  point: Point;
  /** area radius in kilometers */
  radius: Scalars['Float']['input'];
};

/** The connection type for ImageFile. */
export type Attachments = {
  __typename?: 'Attachments';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttachmentsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ImageFile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AttachmentsEdge = {
  __typename?: 'AttachmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ImageFile>;
};

export type Banner = {
  __typename?: 'Banner';
  alt: Scalars['String']['output'];
  category: Scalars['ISO8601DateTime']['output'];
  country: Country;
  countryCode: Scalars['String']['output'];
  countryId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  files: Scalars['JSON']['output'];
  href?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageDetail?: Maybe<PageDetail>;
  pageDetailId?: Maybe<Scalars['ID']['output']>;
  position: Scalars['Int']['output'];
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['ID']['output']>;
  section?: Maybe<Section>;
  sectionId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validFrom: Scalars['ISO8601DateTime']['output'];
  validTo?: Maybe<Scalars['ISO8601DateTime']['output']>;
  visible: Scalars['Boolean']['output'];
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Banner>;
};

/** The connection type for Banner. */
export type Banners = {
  __typename?: 'Banners';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Banner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BillingDataChanges = {
  __typename?: 'BillingDataChanges';
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<CompanyTypeEnum>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  representName?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
};

/** Reservations which can be returned for book */
export type BookReservation = ManualReservation | Reservation;

export type CalendarRevision = {
  __typename?: 'CalendarRevision';
  createdAt: Scalars['Date']['output'];
  currentTenant: Scalars['String']['output'];
  endsOn: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  restaurant: Restaurant;
  startsOn: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  canBeDestroyed: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  expiryDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  last4Digits: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  user: User;
};

export enum CardTransactionTypeEnum {
  Oneclick = 'ONECLICK',
  Oneshot = 'ONESHOT'
}

export type ClientConfig = {
  __typename?: 'ClientConfig';
  currentTenant: Scalars['String']['output'];
  d2dDefaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  d2dReservableFrom: Scalars['ISO8601DateTime']['output'];
  mgmEnabled: Scalars['Boolean']['output'];
  reservableExtraDefaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  reservableExtraReservableFrom: Scalars['ISO8601DateTime']['output'];
};

export type Collection = {
  __typename?: 'Collection';
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  restaurants: Array<Restaurant>;
};


export type CollectionImageUrlArgs = {
  version?: InputMaybe<ImageVersionEnum>;
};

export enum CollectionCategoryEnum {
  Festivaleditionrestaurant = 'FESTIVALEDITIONRESTAURANT',
  Reservableextra = 'RESERVABLEEXTRA',
  Restaurant = 'RESTAURANT'
}

/** An edge in a connection. */
export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PolymorphicCollection>;
};

export type CollectionElement = {
  __typename?: 'CollectionElement';
  currentTenant: Scalars['String']['output'];
  elementId: Scalars['ID']['output'];
  elementType: Scalars['String']['output'];
  imageFiles: Array<ImageFile>;
};

/** The connection type for PolymorphicCollection. */
export type Collections = {
  __typename?: 'Collections';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PolymorphicCollection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Company = {
  __typename?: 'Company';
  currentTenant: Scalars['String']['output'];
  description: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum CompanyTypeEnum {
  /** Spółdzielnia */
  Cooperative = 'COOPERATIVE',
  /** Spółka akcyjna */
  JointStockCompany = 'JOINT_STOCK_COMPANY',
  /** Spółka komandytowo-akcyjna */
  LimitedJointStockPartnership = 'LIMITED_JOINT_STOCK_PARTNERSHIP',
  /** Spółka z o.o-komandytowa */
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  /** Spółka komandytowa */
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  /** Spółka cywilna */
  Partnership = 'PARTNERSHIP',
  /** Osoba fizyczna prowadząca działalność gosp. */
  Person = 'PERSON',
  /** Spółka z o.o. */
  PrivateLimitedCompany = 'PRIVATE_LIMITED_COMPANY',
  /** Spółka jawna */
  PublicCompany = 'PUBLIC_COMPANY'
}

/** Autogenerated return type of ConfirmUser */
export type ConfirmUserPayload = {
  __typename?: 'ConfirmUserPayload';
  authToken: Scalars['String']['output'];
  user: User;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nativeName: Scalars['String']['output'];
};

/** Autogenerated return type of CreateGuest */
export type CreateGuestPayload = {
  __typename?: 'CreateGuestPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  currentTenant: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type CrossSell = {
  __typename?: 'CrossSell';
  adult: Scalars['Boolean']['output'];
  categorySlug?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  dailies: Array<Daily>;
  description: Scalars['String']['output'];
  extraDescription?: Maybe<Scalars['String']['output']>;
  festivalDrink: Scalars['Boolean']['output'];
  festivalEditionRestaurants: Array<FestivalEditionRestaurant>;
  fill: Scalars['Boolean']['output'];
  forced: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageFile>;
  internal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Limited to people count on reservation */
  peopleCountLimited: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  quantityLimitedToOne: Scalars['Boolean']['output'];
};

export type CrossSellData = {
  __typename?: 'CrossSellData';
  crossSell: CrossSell;
  currentTenant: Scalars['String']['output'];
  soldCount: Scalars['Int']['output'];
};

export enum CrossSellOrderField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

/** The connection type for CrossSell. */
export type CrossSells = {
  __typename?: 'CrossSells';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CrossSellsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CrossSell>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CrossSellsEdge = {
  __typename?: 'CrossSellsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CrossSell>;
};

export type CrossSellsList = {
  crossSellId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type CrossSellsOrderBy = {
  direction: OrderDirection;
  field: CrossSellOrderField;
};

export type Daily = {
  __typename?: 'Daily';
  allCrossSells: Array<CrossSell>;
  availableReservationTypes: Array<ReservationTypeEnum>;
  breakAfterReservation: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  crossSells: Array<CrossSell>;
  currentTenant: Scalars['String']['output'];
  dailyCrossSells: Array<DailyCrossSell>;
  defaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  defaultReservationType: ReservationTypeEnum;
  enabled: Scalars['Boolean']['output'];
  endsAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menus: Array<Menu>;
  menusPerPerson: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  name: Scalars['String']['output'];
  possibleSlots?: Maybe<Array<Scalars['Int']['output']>>;
  price: Scalars['Float']['output'];
  resExtraSlotAvailabilityTimeLimitInMinutes: Scalars['Int']['output'];
  reservableFrom: Scalars['ISO8601DateTime']['output'];
  restaurant: Restaurant;
  rulesEditable: Scalars['Boolean']['output'];
  slotAvailabilityTimeLimitInMinutes: Scalars['Int']['output'];
  slotDefinitions?: Maybe<Array<SlotDefinition>>;
  slotDuration: Scalars['Int']['output'];
  startsAt: Scalars['Date']['output'];
};

export type DailyCrossSell = {
  __typename?: 'DailyCrossSell';
  crossSell: CrossSell;
  currentTenant: Scalars['String']['output'];
  daily: Daily;
  enabled: Scalars['Boolean']['output'];
};

export type DateRange = {
  endsOn: Scalars['Date']['input'];
  startsOn: Scalars['Date']['input'];
};

export type DecodedRestaurant = {
  __typename?: 'DecodedRestaurant';
  currentTenant: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  sourceId?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  distance?: Maybe<Scalars['Int']['output']>;
  flatNo?: Maybe<Scalars['String']['output']>;
  houseNo?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type DeliveryAddressInput = {
  city: Scalars['String']['input'];
  flatNo?: InputMaybe<Scalars['String']['input']>;
  houseNo: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type DiscountAssignment = {
  __typename?: 'DiscountAssignment';
  currentTenant: Scalars['String']['output'];
  discountable?: Maybe<Discountable>;
  discountableId?: Maybe<Scalars['ID']['output']>;
  discountableType: DiscountableTypeEnum;
};

export enum DiscountBillingMethodEnum {
  Barter = 'BARTER',
  Free = 'FREE',
  NoInfo = 'NO_INFO',
  Paid = 'PAID'
}

export enum DiscountOrder {
  Recent = 'RECENT'
}

export enum DiscountTypes {
  All = 'ALL',
  PeopleCount = 'PEOPLE_COUNT',
  Percent = 'PERCENT',
  Total = 'TOTAL'
}

export type Discountable = Event | FestivalEdition;

export enum DiscountableTypeEnum {
  Event = 'EVENT',
  FestivalEdition = 'FESTIVAL_EDITION',
  ReservableExtra = 'RESERVABLE_EXTRA'
}

/** The connection type for Redeemable. */
export type Discounts = {
  __typename?: 'Discounts';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscountsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Redeemable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DiscountsEdge = {
  __typename?: 'DiscountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Redeemable>;
};

export type Document = {
  __typename?: 'Document';
  currentTenant: Scalars['String']['output'];
  festivalEdition?: Maybe<FestivalEdition>;
  id: Scalars['ID']['output'];
  richText: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: DocumentTypeEnum;
};

export enum DocumentTypeEnum {
  AgreementTemplate = 'AGREEMENT_TEMPLATE',
  ClubAgreementTemplate = 'CLUB_AGREEMENT_TEMPLATE',
  MainRegulation = 'MAIN_REGULATION',
  OtherRegulation = 'OTHER_REGULATION',
  PrivacyPolicy = 'PRIVACY_POLICY',
  Regulation = 'REGULATION'
}

/** The connection type for Document. */
export type Documents = {
  __typename?: 'Documents';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentsEdge = {
  __typename?: 'DocumentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export type Event = {
  __typename?: 'Event';
  address: Scalars['String']['output'];
  availableReservationTypes: Array<ReservationTypeEnum>;
  code: Scalars['String']['output'];
  currentTenant: Scalars['String']['output'];
  defaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  defaultReservationType: ReservationTypeEnum;
  description: Scalars['String']['output'];
  endsAt: Scalars['ISO8601DateTime']['output'];
  external: Scalars['Boolean']['output'];
  externalUrl?: Maybe<Scalars['String']['output']>;
  global?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageFiles: Array<ImageFile>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  maxMenusAmount: Scalars['Int']['output'];
  maxPeopleCount: Scalars['Int']['output'];
  menus?: Maybe<Array<Menu>>;
  menusPerPerson: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  minMenusAmount: Scalars['Int']['output'];
  minPeopleCount: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  region?: Maybe<Region>;
  reservableFrom: Scalars['ISO8601DateTime']['output'];
  reservationFeeUnitPrice: Scalars['Float']['output'];
  restaurant?: Maybe<Restaurant>;
  rulesEditable: Scalars['Boolean']['output'];
  slotless: Scalars['Boolean']['output'];
  slots?: Maybe<Array<SlotDefinition>>;
  startsAt: Scalars['ISO8601DateTime']['output'];
  title: Scalars['String']['output'];
};

export enum EventOrder {
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  Recent = 'RECENT',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC'
}

/** The connection type for Event. */
export type Events = {
  __typename?: 'Events';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Event>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Event>;
};

/** Autogenerated return type of FacebookSignIn */
export type FacebookSignInPayload = {
  __typename?: 'FacebookSignInPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Favorite = {
  __typename?: 'Favorite';
  currentTenant: Scalars['String']['output'];
  restaurant: Restaurant;
  user: User;
};

export type Festival = {
  __typename?: 'Festival';
  about: Scalars['String']['output'];
  attendeesCount: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  currentTenant: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageFile>;
  logo?: Maybe<ImageFile>;
  metadatas: Array<Metadata>;
  name: Scalars['String']['output'];
  reservationClosingRule: ReservationClosingRule;
  shortDescription: Scalars['String']['output'];
  slogan: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum FestivalCodesEnum {
  Bfw = 'bfw',
  Bw = 'bw',
  Cc = 'cc',
  Cf = 'cf',
  Chm = 'chm',
  Dw = 'dw',
  Fdw = 'fdw',
  Gw = 'gw',
  Korw = 'korw',
  Neon = 'neon',
  Pw = 'pw',
  Rctjz = 'rctjz',
  Rw = 'rw',
  Rwx = 'rwx'
}

export type FestivalEdition = {
  __typename?: 'FestivalEdition';
  agreementTemplate?: Maybe<Document>;
  allDaySlots: Scalars['Boolean']['output'];
  availableReservationTypes: Array<ReservationTypeEnum>;
  blikDiscount?: Maybe<ValueCoupon>;
  blikEnabled: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  crossSellAssignments: Array<FestivalEditionCrossSell>;
  crossSells: Array<CrossSell>;
  currentTenant: Scalars['String']['output'];
  defaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  defaultReservationType: ReservationTypeEnum;
  enabled: Scalars['Boolean']['output'];
  endsAt: Scalars['ISO8601DateTime']['output'];
  externalSale: Scalars['Boolean']['output'];
  features: Array<FestivalEditionFeaturesTypeEnum>;
  festival: Festival;
  festivalDrinkSaleForced: FestivalEditionDrinkSaleEnum;
  /** List of festival edition restaurants */
  festivalEditionRestaurants: FestivalEditionRestaurants;
  forceLabels: Scalars['Boolean']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use slots attribute to get hours */
  hours: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryItemAssignments: Array<FestivalEditionInventoryItem>;
  inventoryItems: Array<InventoryItem>;
  maxMenusAmount: Scalars['Int']['output'];
  maxPeopleCount: Scalars['Int']['output'];
  menuPositions: Array<FestivalEditionMenuPosition>;
  menusPerPerson: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  minMenusAmount: Scalars['Int']['output'];
  minPeopleCount: Scalars['Int']['output'];
  overridableTagTypes: Array<OverridableTagTypeEnum>;
  presaleStartsAt: Scalars['ISO8601DateTime']['output'];
  price: Scalars['Float']['output'];
  regulation?: Maybe<Document>;
  /** @deprecated Only one regulation per festival - use regualation attribute */
  regulations: Array<Document>;
  reservableFrom: Scalars['ISO8601DateTime']['output'];
  reservationFeeUnitPrice: Scalars['Float']['output'];
  reservationStartsAt: Scalars['ISO8601DateTime']['output'];
  reservationsCancellable: Scalars['Boolean']['output'];
  reservationsEditable: Scalars['Boolean']['output'];
  slotDuration: Scalars['Int']['output'];
  slots?: Maybe<Array<SlotDefinition>>;
  sponsors: Array<Sponsor>;
  startsAt: Scalars['ISO8601DateTime']['output'];
  state: FestivalStateEnum;
  submission?: Maybe<Submission>;
  voucher: Scalars['Boolean']['output'];
};


export type FestivalEditionFestivalEditionRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  reservation_filters?: InputMaybe<ReservationFilter>;
  tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  zone_id?: InputMaybe<Scalars['ID']['input']>;
};

export type FestivalEditionCrossSell = {
  __typename?: 'FestivalEditionCrossSell';
  crossSell: CrossSell;
  currentTenant: Scalars['String']['output'];
  enabledRegions: Array<Region>;
  ferAutoFilled: Scalars['Boolean']['output'];
};

export type FestivalEditionData = {
  __typename?: 'FestivalEditionData';
  crossSellData: Array<CrossSellData>;
  currentTenant: Scalars['String']['output'];
  festivalEdition: FestivalEdition;
  incomeData: IncomeData;
  peopleData: Array<PeopleData>;
  reservationsData: Array<ReservationsData>;
  restaurant?: Maybe<Restaurant>;
  seatsData: SeatsData;
  slotsData: Array<SlotsData>;
  usersData: UsersData;
};

export enum FestivalEditionDrinkSaleEnum {
  GuestCount = 'GUEST_COUNT',
  None = 'NONE',
  One = 'ONE'
}

/** An edge in a connection. */
export type FestivalEditionEdge = {
  __typename?: 'FestivalEditionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FestivalEdition>;
};

export enum FestivalEditionFeaturesTypeEnum {
  FollowUpSurvey = 'FOLLOW_UP_SURVEY',
  LastGuestFree = 'LAST_GUEST_FREE'
}

export type FestivalEditionInventoryItem = {
  __typename?: 'FestivalEditionInventoryItem';
  currentTenant: Scalars['String']['output'];
  festivalEdition: FestivalEdition;
  id: Scalars['ID']['output'];
  inventoryItem: InventoryItem;
  priceRequired: Scalars['Boolean']['output'];
  quantityRequired: Scalars['Boolean']['output'];
};

export type FestivalEditionMenuPosition = {
  __typename?: 'FestivalEditionMenuPosition';
  currentTenant: Scalars['String']['output'];
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export enum FestivalEditionOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID'
}

export type FestivalEditionRestaurant = {
  __typename?: 'FestivalEditionRestaurant';
  agreement?: Maybe<Agreement>;
  allDaySlots: Scalars['Boolean']['output'];
  availableCrossSells: Array<CrossSell>;
  availableReservationTypes: Array<ReservationTypeEnum>;
  crossSellAssignments: Array<FestivalEditionRestaurantCrossSell>;
  crossSells: Array<CrossSell>;
  currentTenant: Scalars['String']['output'];
  defaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  defaultReservationType: ReservationTypeEnum;
  externalSaleUrl?: Maybe<Scalars['String']['output']>;
  ferInventoryItems: Array<FestivalEditionRestaurantInventoryItem>;
  festivalEdition: FestivalEdition;
  forceAgreement: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageFiles: Array<ImageFile>;
  incomingChanges?: Maybe<FestivalEditionRestaurantChanges>;
  labelA?: Maybe<Scalars['String']['output']>;
  labelB?: Maybe<Scalars['String']['output']>;
  menus?: Maybe<Array<Menu>>;
  menusAmountChangeable: Scalars['Boolean']['output'];
  menusCount: Scalars['Int']['output'];
  menusPerPerson: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  notes?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  reservableFrom: Scalars['ISO8601DateTime']['output'];
  restaurant: Restaurant;
  rulesEditable: Scalars['Boolean']['output'];
  rulesLocked: Scalars['Boolean']['output'];
  sellableCrossSells: Array<CrossSell>;
  signed: Scalars['Boolean']['output'];
  status: StatusableEnum;
  submission?: Maybe<Submission>;
  tags: Array<Tag>;
};

export type FestivalEditionRestaurantChanges = {
  __typename?: 'FestivalEditionRestaurantChanges';
  crossSellAssignments?: Maybe<Array<FestivalEditionRestaurantCrossSell>>;
  crossSellIds?: Maybe<Array<Scalars['ID']['output']>>;
  currentTenant: Scalars['String']['output'];
  ferInventoryItems?: Maybe<Array<FestivalEditionRestaurantInventoryItem>>;
  imageFiles?: Maybe<Array<ImageFile>>;
  labelA?: Maybe<Scalars['String']['output']>;
  labelB?: Maybe<Scalars['String']['output']>;
  menus?: Maybe<Array<Menu>>;
  notes?: Maybe<Scalars['String']['output']>;
  tagIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type FestivalEditionRestaurantCrossSell = {
  __typename?: 'FestivalEditionRestaurantCrossSell';
  createdAt: Scalars['ISO8601DateTime']['output'];
  crossSellId?: Maybe<Scalars['ID']['output']>;
  currentTenant: Scalars['String']['output'];
  festivalEditionCrossSell?: Maybe<FestivalEditionCrossSell>;
  festivalEditionRestaurant: FestivalEditionRestaurant;
  saleEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** An edge in a connection. */
export type FestivalEditionRestaurantEdge = {
  __typename?: 'FestivalEditionRestaurantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FestivalEditionRestaurant>;
};

export type FestivalEditionRestaurantInventoryItem = {
  __typename?: 'FestivalEditionRestaurantInventoryItem';
  currentTenant: Scalars['String']['output'];
  festivalEditionInventoryItem: FestivalEditionInventoryItem;
  festivalEditionRestaurant: FestivalEditionRestaurant;
  inventoryItem: InventoryItem;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** The connection type for FestivalEditionRestaurant. */
export type FestivalEditionRestaurants = {
  __typename?: 'FestivalEditionRestaurants';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FestivalEditionRestaurantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FestivalEditionRestaurant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** The connection type for FestivalEdition. */
export type FestivalEditions = {
  __typename?: 'FestivalEditions';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FestivalEditionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FestivalEdition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FestivalEditionsOrderBy = {
  direction: OrderDirection;
  field: FestivalEditionOrderField;
};

export enum FestivalOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Name = 'NAME'
}

export enum FestivalStateEnum {
  Presale = 'PRESALE',
  Sale = 'SALE',
  Unavailable = 'UNAVAILABLE',
  Voucher = 'VOUCHER'
}

export enum FestivalTimingEnum {
  Current = 'CURRENT',
  CurrentOrFuture = 'CURRENT_OR_FUTURE',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type FestivalsOrderBy = {
  direction: OrderDirection;
  field: FestivalOrderField;
};

/** Autogenerated return type of GoogleSignIn */
export type GoogleSignInPayload = {
  __typename?: 'GoogleSignInPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type HourRange = {
  __typename?: 'HourRange';
  currentTenant: Scalars['String']['output'];
  discount: Scalars['Int']['output'];
  endsAt: Scalars['Int']['output'];
  startsAt: Scalars['Int']['output'];
  totalAllocation: Scalars['Int']['output'];
};

export type Hours = {
  __typename?: 'Hours';
  currentTenant: Scalars['String']['output'];
  day: Scalars['String']['output'];
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type ImageFile = {
  __typename?: 'ImageFile';
  attachable?: Maybe<Restaurant>;
  createdAt: Scalars['Date']['output'];
  currentTenant: Scalars['String']['output'];
  /** @deprecated You should get it directly */
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  original?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};


export type ImageFileFileUrlArgs = {
  version?: InputMaybe<ImageVersionEnum>;
};

export enum ImageVersionEnum {
  Original = 'ORIGINAL',
  Preview = 'PREVIEW',
  Thumbnail = 'THUMBNAIL'
}

export type IncomeData = {
  __typename?: 'IncomeData';
  crossSellsIncome: Scalars['Float']['output'];
  currentTenant: Scalars['String']['output'];
  overallIncome: Scalars['Float']['output'];
  ticketsIncome: Scalars['Float']['output'];
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageFile>;
  name: Scalars['String']['output'];
};

/** The connection type for InventoryItem. */
export type InventoryItems = {
  __typename?: 'InventoryItems';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InventoryItemsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InventoryItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InventoryItemsEdge = {
  __typename?: 'InventoryItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InventoryItem>;
};

export type KlarnaOrder = {
  __typename?: 'KlarnaOrder';
  currentTenant: Scalars['String']['output'];
  fraudStatus?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  redirectUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Like = {
  __typename?: 'Like';
  currentTenant: Scalars['String']['output'];
  review: Review;
  user: User;
};

export enum LocaleEnum {
  Cs = 'cs',
  En = 'en',
  Pl = 'pl'
}

export type ManualReservation = {
  __typename?: 'ManualReservation';
  code: Scalars['String']['output'];
  confirmed: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  guestNotes?: Maybe<Scalars['String']['output']>;
  hall?: Maybe<Scalars['String']['output']>;
  hour: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  peopleCount: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  restaurant: Restaurant;
  restauratorNotes?: Maybe<Scalars['String']['output']>;
  slot: Scalars['Int']['output'];
  slotDuration: Scalars['Int']['output'];
  /** @deprecated Use slot attribute */
  slotVariant?: Maybe<Scalars['Int']['output']>;
  status: ManualReservationStatusEnum;
  table?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
};

export enum ManualReservationStatusEnum {
  Arrived = 'ARRIVED',
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  NoShow = 'NO_SHOW',
  Pending = 'PENDING'
}

export type Menu = {
  __typename?: 'Menu';
  currentTenant: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  imageFile?: Maybe<ImageFile>;
  label: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  positions: Array<MenuPosition>;
  positionsCount: Scalars['Int']['output'];
  tags: Array<Tag>;
};

export enum MenuKindEnum {
  A = 'A',
  Ab = 'AB',
  Abc = 'ABC',
  Abcd = 'ABCD',
  Abcde = 'ABCDE',
  Abcdef = 'ABCDEF',
  Abcdefg = 'ABCDEFG',
  Abcdefgh = 'ABCDEFGH',
  Abcdefh = 'ABCDEFH',
  Abcdeg = 'ABCDEG',
  Abcdegh = 'ABCDEGH',
  Abcdeh = 'ABCDEH',
  Abcdf = 'ABCDF',
  Abcdfg = 'ABCDFG',
  Abcdfgh = 'ABCDFGH',
  Abcdfh = 'ABCDFH',
  Abcdg = 'ABCDG',
  Abcdgh = 'ABCDGH',
  Abcdh = 'ABCDH',
  Abce = 'ABCE',
  Abcef = 'ABCEF',
  Abcefg = 'ABCEFG',
  Abcefgh = 'ABCEFGH',
  Abcefh = 'ABCEFH',
  Abceg = 'ABCEG',
  Abcegh = 'ABCEGH',
  Abceh = 'ABCEH',
  Abcf = 'ABCF',
  Abcfg = 'ABCFG',
  Abcfgh = 'ABCFGH',
  Abcfh = 'ABCFH',
  Abcg = 'ABCG',
  Abcgh = 'ABCGH',
  Abch = 'ABCH',
  Abd = 'ABD',
  Abde = 'ABDE',
  Abdef = 'ABDEF',
  Abdefg = 'ABDEFG',
  Abdefgh = 'ABDEFGH',
  Abdefh = 'ABDEFH',
  Abdeg = 'ABDEG',
  Abdegh = 'ABDEGH',
  Abdeh = 'ABDEH',
  Abdf = 'ABDF',
  Abdfg = 'ABDFG',
  Abdfgh = 'ABDFGH',
  Abdfh = 'ABDFH',
  Abdg = 'ABDG',
  Abdgh = 'ABDGH',
  Abdh = 'ABDH',
  Abe = 'ABE',
  Abef = 'ABEF',
  Abefg = 'ABEFG',
  Abefgh = 'ABEFGH',
  Abefh = 'ABEFH',
  Abeg = 'ABEG',
  Abegh = 'ABEGH',
  Abeh = 'ABEH',
  Abf = 'ABF',
  Abfg = 'ABFG',
  Abfgh = 'ABFGH',
  Abfh = 'ABFH',
  Abg = 'ABG',
  Abgh = 'ABGH',
  Abh = 'ABH',
  Ac = 'AC',
  Acd = 'ACD',
  Acde = 'ACDE',
  Acdef = 'ACDEF',
  Acdefg = 'ACDEFG',
  Acdefgh = 'ACDEFGH',
  Acdefh = 'ACDEFH',
  Acdeg = 'ACDEG',
  Acdegh = 'ACDEGH',
  Acdeh = 'ACDEH',
  Acdf = 'ACDF',
  Acdfg = 'ACDFG',
  Acdfgh = 'ACDFGH',
  Acdfh = 'ACDFH',
  Acdg = 'ACDG',
  Acdgh = 'ACDGH',
  Acdh = 'ACDH',
  Ace = 'ACE',
  Acef = 'ACEF',
  Acefg = 'ACEFG',
  Acefgh = 'ACEFGH',
  Acefh = 'ACEFH',
  Aceg = 'ACEG',
  Acegh = 'ACEGH',
  Aceh = 'ACEH',
  Acf = 'ACF',
  Acfg = 'ACFG',
  Acfgh = 'ACFGH',
  Acfh = 'ACFH',
  Acg = 'ACG',
  Acgh = 'ACGH',
  Ach = 'ACH',
  Ad = 'AD',
  Ade = 'ADE',
  Adef = 'ADEF',
  Adefg = 'ADEFG',
  Adefgh = 'ADEFGH',
  Adefh = 'ADEFH',
  Adeg = 'ADEG',
  Adegh = 'ADEGH',
  Adeh = 'ADEH',
  Adf = 'ADF',
  Adfg = 'ADFG',
  Adfgh = 'ADFGH',
  Adfh = 'ADFH',
  Adg = 'ADG',
  Adgh = 'ADGH',
  Adh = 'ADH',
  Ae = 'AE',
  Aef = 'AEF',
  Aefg = 'AEFG',
  Aefgh = 'AEFGH',
  Aefh = 'AEFH',
  Aeg = 'AEG',
  Aegh = 'AEGH',
  Aeh = 'AEH',
  Af = 'AF',
  Afg = 'AFG',
  Afgh = 'AFGH',
  Afh = 'AFH',
  Ag = 'AG',
  Agh = 'AGH',
  Ah = 'AH',
  B = 'B',
  Bc = 'BC',
  Bcd = 'BCD',
  Bcde = 'BCDE',
  Bcdef = 'BCDEF',
  Bcdefg = 'BCDEFG',
  Bcdefgh = 'BCDEFGH',
  Bcdefh = 'BCDEFH',
  Bcdeg = 'BCDEG',
  Bcdegh = 'BCDEGH',
  Bcdeh = 'BCDEH',
  Bcdf = 'BCDF',
  Bcdfg = 'BCDFG',
  Bcdfgh = 'BCDFGH',
  Bcdfh = 'BCDFH',
  Bcdg = 'BCDG',
  Bcdgh = 'BCDGH',
  Bcdh = 'BCDH',
  Bce = 'BCE',
  Bcef = 'BCEF',
  Bcefg = 'BCEFG',
  Bcefgh = 'BCEFGH',
  Bcefh = 'BCEFH',
  Bceg = 'BCEG',
  Bcegh = 'BCEGH',
  Bceh = 'BCEH',
  Bcf = 'BCF',
  Bcfg = 'BCFG',
  Bcfgh = 'BCFGH',
  Bcfh = 'BCFH',
  Bcg = 'BCG',
  Bcgh = 'BCGH',
  Bch = 'BCH',
  Bd = 'BD',
  Bde = 'BDE',
  Bdef = 'BDEF',
  Bdefg = 'BDEFG',
  Bdefgh = 'BDEFGH',
  Bdefh = 'BDEFH',
  Bdeg = 'BDEG',
  Bdegh = 'BDEGH',
  Bdeh = 'BDEH',
  Bdf = 'BDF',
  Bdfg = 'BDFG',
  Bdfgh = 'BDFGH',
  Bdfh = 'BDFH',
  Bdg = 'BDG',
  Bdgh = 'BDGH',
  Bdh = 'BDH',
  Be = 'BE',
  Bef = 'BEF',
  Befg = 'BEFG',
  Befgh = 'BEFGH',
  Befh = 'BEFH',
  Beg = 'BEG',
  Begh = 'BEGH',
  Beh = 'BEH',
  Bf = 'BF',
  Bfg = 'BFG',
  Bfgh = 'BFGH',
  Bfh = 'BFH',
  Bg = 'BG',
  Bgh = 'BGH',
  Bh = 'BH',
  C = 'C',
  Cd = 'CD',
  Cde = 'CDE',
  Cdef = 'CDEF',
  Cdefg = 'CDEFG',
  Cdefgh = 'CDEFGH',
  Cdefh = 'CDEFH',
  Cdeg = 'CDEG',
  Cdegh = 'CDEGH',
  Cdeh = 'CDEH',
  Cdf = 'CDF',
  Cdfg = 'CDFG',
  Cdfgh = 'CDFGH',
  Cdfh = 'CDFH',
  Cdg = 'CDG',
  Cdgh = 'CDGH',
  Cdh = 'CDH',
  Ce = 'CE',
  Cef = 'CEF',
  Cefg = 'CEFG',
  Cefgh = 'CEFGH',
  Cefh = 'CEFH',
  Ceg = 'CEG',
  Cegh = 'CEGH',
  Ceh = 'CEH',
  Cf = 'CF',
  Cfg = 'CFG',
  Cfgh = 'CFGH',
  Cfh = 'CFH',
  Cg = 'CG',
  Cgh = 'CGH',
  Ch = 'CH',
  D = 'D',
  De = 'DE',
  Def = 'DEF',
  Defg = 'DEFG',
  Defgh = 'DEFGH',
  Defh = 'DEFH',
  Deg = 'DEG',
  Degh = 'DEGH',
  Deh = 'DEH',
  Df = 'DF',
  Dfg = 'DFG',
  Dfgh = 'DFGH',
  Dfh = 'DFH',
  Dg = 'DG',
  Dgh = 'DGH',
  Dh = 'DH',
  E = 'E',
  Ef = 'EF',
  Efg = 'EFG',
  Efgh = 'EFGH',
  Efh = 'EFH',
  Eg = 'EG',
  Egh = 'EGH',
  Eh = 'EH',
  F = 'F',
  Fg = 'FG',
  Fgh = 'FGH',
  Fh = 'FH',
  G = 'G',
  Gh = 'GH',
  H = 'H'
}

export type MenuPosition = {
  __typename?: 'MenuPosition';
  content: Scalars['String']['output'];
  currentTenant: Scalars['String']['output'];
  imageFiles: Array<ImageFile>;
  index: Scalars['Int']['output'];
  menu: Menu;
  name: Scalars['String']['output'];
  restaurant: Restaurant;
  title?: Maybe<Scalars['String']['output']>;
};

/** The connection type for MenuPosition. */
export type MenuPositions = {
  __typename?: 'MenuPositions';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MenuPositionsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MenuPosition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MenuPositionsEdge = {
  __typename?: 'MenuPositionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MenuPosition>;
};

export type Metadata = {
  __typename?: 'Metadata';
  currentTenant: Scalars['String']['output'];
  dataType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  metadatableId?: Maybe<Scalars['Int']['output']>;
  metadatableType?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRegulation: User;
  addReservationGuest: ReservationGuest;
  addUrlResolverEntry: Scalars['Boolean']['output'];
  adminCancelReservation: Reservation;
  appleSignIn: AppleSignInPayload;
  cancelDailyReservation: Reservation;
  cancelReservation: Reservation;
  confirmManualReservation: ManualReservation;
  confirmReservationGuest: ReservationGuest;
  confirmUser: ConfirmUserPayload;
  connectReservation: Reservation;
  createDailyReservation: Reservation;
  createEventReservation: Reservation;
  createFavorite: Favorite;
  createFestivalReservation: Reservation;
  createGuest: CreateGuestPayload;
  createLike: Like;
  createManualReservation: ManualReservation;
  createPageDetail: PageDetail;
  createReview: Review;
  createUser: CreateUserPayload;
  createVoucher: Voucher;
  currentTenant: Scalars['String']['output'];
  deleteCard?: Maybe<Card>;
  deleteUser: Scalars['Boolean']['output'];
  destroyFavorite: Favorite;
  destroyLike: Like;
  destroyPageDetail: PageDetail;
  facebookSignIn: FacebookSignInPayload;
  googleSignIn: GoogleSignInPayload;
  klarnaCreateOrder: KlarnaOrder;
  klarnaCreateSession: Session;
  klarnaUpdateSession: Scalars['Boolean']['output'];
  moderateReview: Review;
  newsletterSignUp: NewsletterSignUp;
  payuCreateOrder: CreateOrderResponse;
  purchaseReservation: PurchaseReservationPayload;
  purchaseVoucher: PurchaseVoucherPayload;
  redeemDiscount: Reservation;
  rememberMe: ManualReservation;
  resetPasswordRequest: ResetPasswordRequestPayload;
  restaurateurUpdateReservation: Reservation;
  sendReservationNotification: Scalars['Boolean']['output'];
  sendSmsCode: User;
  setDefaultCard: Card;
  setManualReservationStatus: ManualReservation;
  setPassword: User;
  signIn?: Maybe<SignInPayload>;
  signInRestaurant: Restaurant;
  signOut: Scalars['Boolean']['output'];
  signOutRestaurant: Scalars['Boolean']['output'];
  updateDailyReservation: Reservation;
  updateEventReservation: Reservation;
  updateFestivalReservation: Reservation;
  updateManualReservation: ManualReservation;
  updateOrder: Order;
  updatePageDetail: PageDetail;
  updateUser: User;
  verifySmsCode: User;
};


export type MutationAddReservationGuestArgs = {
  email: Scalars['String']['input'];
  reservationId: Scalars['ID']['input'];
};


export type MutationAddUrlResolverEntryArgs = {
  objectId: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type MutationAdminCancelReservationArgs = {
  code: Scalars['String']['input'];
};


export type MutationAppleSignInArgs = {
  appleIdentityToken: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  userIdentityId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelDailyReservationArgs = {
  code: Scalars['String']['input'];
};


export type MutationCancelReservationArgs = {
  code: Scalars['String']['input'];
};


export type MutationConfirmManualReservationArgs = {
  code: Scalars['String']['input'];
};


export type MutationConfirmReservationGuestArgs = {
  token: Scalars['String']['input'];
};


export type MutationConfirmUserArgs = {
  confirmationToken: Scalars['String']['input'];
};


export type MutationConnectReservationArgs = {
  code: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCreateDailyReservationArgs = {
  crossSellsList?: InputMaybe<Array<CrossSellsList>>;
  date: Scalars['Date']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  peopleCount: Scalars['Int']['input'];
  reservableExtraAttrs?: InputMaybe<Array<ReservableExtraAttrInput>>;
  reservationSource?: InputMaybe<Scalars['Int']['input']>;
  restaurantId: Scalars['ID']['input'];
  slot: Scalars['Int']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateEventReservationArgs = {
  date: Scalars['Date']['input'];
  eventId: Scalars['ID']['input'];
  menus?: InputMaybe<Array<Scalars['Int']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  peopleCount: Scalars['Int']['input'];
  reservationSource?: InputMaybe<Scalars['Int']['input']>;
  slot: Scalars['Int']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFavoriteArgs = {
  restaurantId: Scalars['ID']['input'];
};


export type MutationCreateFestivalReservationArgs = {
  crossSellsList?: InputMaybe<Array<CrossSellsList>>;
  date: Scalars['Date']['input'];
  festivalEditionId: Scalars['ID']['input'];
  guestEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  menus: Array<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  reservationSource?: InputMaybe<Scalars['Int']['input']>;
  reservationType?: InputMaybe<ReservationTypeEnum>;
  restaurantId: Scalars['ID']['input'];
  slot: Scalars['Int']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
  voucherCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateGuestArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  marketingConsentAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber: Scalars['String']['input'];
  regulationAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  regulationConsentAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateLikeArgs = {
  reviewId: Scalars['ID']['input'];
};


export type MutationCreateManualReservationArgs = {
  date: Scalars['Date']['input'];
  guestNotes?: InputMaybe<Scalars['String']['input']>;
  hall?: InputMaybe<Scalars['String']['input']>;
  peopleCount: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  restauratorNotes?: InputMaybe<Scalars['String']['input']>;
  slot?: InputMaybe<Scalars['Int']['input']>;
  slotDuration: Scalars['Int']['input'];
  slotVariant?: InputMaybe<Scalars['Int']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePageDetailArgs = {
  middleBannerId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  topBannerId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateReviewArgs = {
  atmosphereRating?: InputMaybe<Scalars['Int']['input']>;
  menuRating?: InputMaybe<Scalars['Int']['input']>;
  ratings: RatingAttributes;
  reservationCode: Scalars['String']['input'];
  securityToken?: InputMaybe<Scalars['String']['input']>;
  staffRating?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateUserArgs = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  consents?: InputMaybe<Array<UserConsentInput>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingConsentAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regulationAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  regulationConsentAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateVoucherArgs = {
  festivalEditionId: Scalars['ID']['input'];
  peopleCount: Scalars['Int']['input'];
  permittedPaymentMethods?: InputMaybe<Array<PaymentProviderEnum>>;
  permittedSources?: InputMaybe<Array<ReservationSourceEnum>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteCardArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDestroyFavoriteArgs = {
  restaurantId: Scalars['ID']['input'];
};


export type MutationDestroyLikeArgs = {
  reviewId: Scalars['ID']['input'];
};


export type MutationDestroyPageDetailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFacebookSignInArgs = {
  accessToken: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGoogleSignInArgs = {
  accessToken: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationKlarnaCreateOrderArgs = {
  authToken: Scalars['String']['input'];
  reservationId: Scalars['ID']['input'];
};


export type MutationKlarnaCreateSessionArgs = {
  reservationId: Scalars['ID']['input'];
};


export type MutationKlarnaUpdateSessionArgs = {
  reservationId: Scalars['ID']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationModerateReviewArgs = {
  id: Scalars['ID']['input'];
  status: ReviewStatusEnum;
};


export type MutationNewsletterSignUpArgs = {
  email: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPayuCreateOrderArgs = {
  extraParams?: InputMaybe<PaymentExtraParamsInput>;
  paymentMethod: PaymentMethodEnum;
  reservationId: Scalars['ID']['input'];
};


export type MutationPurchaseReservationArgs = {
  cardId?: InputMaybe<Scalars['ID']['input']>;
  code: Scalars['String']['input'];
  consents?: InputMaybe<Array<UserConsentInput>>;
  extraParams?: InputMaybe<PaymentExtraParamsInput>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  paymentProvider?: InputMaybe<PaymentProviderEnum>;
  rcRegulationAccord?: InputMaybe<Scalars['Boolean']['input']>;
  restaurantRegulationAccord?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPurchaseVoucherArgs = {
  cardId?: InputMaybe<Scalars['ID']['input']>;
  code: Scalars['String']['input'];
  extraParams?: InputMaybe<PaymentExtraParamsInput>;
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  paymentProvider?: InputMaybe<PaymentProviderEnum>;
};


export type MutationRedeemDiscountArgs = {
  code: Scalars['String']['input'];
  redeemCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRememberMeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  rodoConsent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationResetPasswordRequestArgs = {
  email: Scalars['String']['input'];
};


export type MutationRestaurateurUpdateReservationArgs = {
  code: Scalars['String']['input'];
  hall?: InputMaybe<Scalars['String']['input']>;
  restauratorNotes?: InputMaybe<Scalars['String']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendReservationNotificationArgs = {
  id: Scalars['ID']['input'];
  type: ReservationNotificationTypeEnum;
};


export type MutationSendSmsCodeArgs = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetDefaultCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetManualReservationStatusArgs = {
  id: Scalars['ID']['input'];
  status: ManualReservationStatusEnum;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignInRestaurantArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateDailyReservationArgs = {
  code: Scalars['String']['input'];
  crossSellsList?: InputMaybe<Array<CrossSellsList>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  redeemCode?: InputMaybe<Scalars['String']['input']>;
  reservableExtraAttrs?: InputMaybe<Array<ReservableExtraAttrInput>>;
  slot?: InputMaybe<Scalars['Int']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateEventReservationArgs = {
  code: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  menus?: InputMaybe<Array<Scalars['Int']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  redeemCode?: InputMaybe<Scalars['String']['input']>;
  slot?: InputMaybe<Scalars['Int']['input']>;
  userAcceptedMarketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFestivalReservationArgs = {
  _force?: InputMaybe<Scalars['Boolean']['input']>;
  code: Scalars['String']['input'];
  crossSellsList?: InputMaybe<Array<CrossSellsList>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
  guestEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  menus?: InputMaybe<Array<Scalars['Int']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  redeemCode?: InputMaybe<Scalars['String']['input']>;
  reservationType?: InputMaybe<ReservationTypeEnum>;
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
  slot?: InputMaybe<Scalars['Int']['input']>;
  userAcceptedMarketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateManualReservationArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  guestNotes?: InputMaybe<Scalars['String']['input']>;
  hall?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  restauratorNotes?: InputMaybe<Scalars['String']['input']>;
  slot?: InputMaybe<Scalars['Int']['input']>;
  slotDuration?: InputMaybe<Scalars['Int']['input']>;
  slotVariant?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ManualReservationStatusEnum>;
  table?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  status?: InputMaybe<OrderStatusEnum>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePageDetailArgs = {
  id: Scalars['ID']['input'];
  middleBannerId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  topBannerId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateUserArgs = {
  consents?: InputMaybe<Array<UserConsentInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasMarketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<LocaleEnum>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVerifySmsCodeArgs = {
  code: Scalars['String']['input'];
};

export enum MyReservationsTypeEnum {
  All = 'ALL',
  Upcoming = 'UPCOMING'
}

export type NewsletterSignUp = {
  __typename?: 'NewsletterSignUp';
  currentTenant: Scalars['String']['output'];
  salesmanagoContactId?: Maybe<Scalars['String']['output']>;
};

export type OpeningHoursChanges = {
  __typename?: 'OpeningHoursChanges';
  currentTenant: Scalars['String']['output'];
  day?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type Order = {
  __typename?: 'Order';
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  provider: PaymentProviderEnum;
  status: OrderStatusEnum;
  statusDetails?: Maybe<OrderStatusDetailsEnum>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum OrderStatusDetailsEnum {
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
  AuthorizationFailed = 'AUTHORIZATION_FAILED',
  CardExpired = 'CARD_EXPIRED',
  DeclinedByIssuingBank = 'DECLINED_BY_ISSUING_BANK',
  FraudDetected = 'FRAUD_DETECTED',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidCardData = 'INVALID_CARD_DATA',
  PaymentCanceled = 'PAYMENT_CANCELED',
  PaymentGatewayError = 'PAYMENT_GATEWAY_ERROR',
  PaymentTimeExpired = 'PAYMENT_TIME_EXPIRED',
  PossiblyDuplicatedTransaction = 'POSSIBLY_DUPLICATED_TRANSACTION',
  TransactionDeclined = 'TRANSACTION_DECLINED'
}

export enum OrderStatusEnum {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum OverridableTagTypeEnum {
  Atmosphere = 'ATMOSPHERE',
  Category = 'CATEGORY',
  Cuisine = 'CUISINE',
  Deliveryregion = 'DELIVERYREGION',
  Diet = 'DIET',
  Dish = 'DISH',
  Establishmenttype = 'ESTABLISHMENTTYPE',
  Menutag = 'MENUTAG',
  Occasion = 'OCCASION',
  Other = 'OTHER',
  Price = 'PRICE',
  Reservationtype = 'RESERVATIONTYPE'
}

export type PageDetail = {
  __typename?: 'PageDetail';
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  middleBanner?: Maybe<Banner>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  topBanner?: Maybe<Banner>;
};

/** An edge in a connection. */
export type PageDetailEdge = {
  __typename?: 'PageDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PageDetail>;
};

/** The connection type for PageDetail. */
export type PageDetails = {
  __typename?: 'PageDetails';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageDetailEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PageDetail>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PayByLinkMethod = {
  __typename?: 'PayByLinkMethod';
  brandImageUrl?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  maxAmount: Scalars['Int']['output'];
  minAmount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PaymentExtraParamsInput = {
  blikAppKeyAssignmentId?: InputMaybe<Scalars['String']['input']>;
  blikCode?: InputMaybe<Scalars['String']['input']>;
  blikRegisterToken?: InputMaybe<Scalars['Boolean']['input']>;
  blikTokenValue?: InputMaybe<Scalars['String']['input']>;
  cardTransactionType?: InputMaybe<CardTransactionTypeEnum>;
  continueUrl?: InputMaybe<Scalars['String']['input']>;
  failureUrl?: InputMaybe<Scalars['String']['input']>;
  pblMethodValue?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
  userAddress?: InputMaybe<UserAddressInput>;
};

export enum PaymentMethodEnum {
  BlikAuthorizationCode = 'BLIK_AUTHORIZATION_CODE',
  BlikToken = 'BLIK_TOKEN',
  Card = 'CARD',
  Link = 'LINK',
  Pbl = 'PBL'
}

export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  currentTenant: Scalars['String']['output'];
  payByLinks: Array<PayByLinkMethod>;
};

export enum PaymentProviderEnum {
  Internal = 'INTERNAL',
  Klarna = 'KLARNA',
  Paypo = 'PAYPO',
  Payu = 'PAYU',
  Straal = 'STRAAL'
}

export type PdfFile = {
  __typename?: 'PdfFile';
  attachable?: Maybe<Restaurant>;
  createdAt: Scalars['Date']['output'];
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  original?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
};

export type PeopleData = {
  __typename?: 'PeopleData';
  currentTenant: Scalars['String']['output'];
  peopleCount: Scalars['Int']['output'];
  reservationsCount: Scalars['Int']['output'];
};

export type PercentageCoupon = Redeemable & {
  __typename?: 'PercentageCoupon';
  billingMethod: DiscountBillingMethodEnum;
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  discountAssignments: Array<DiscountAssignment>;
  enabled: Scalars['Boolean']['output'];
  events: Array<FestivalEdition>;
  expired: Scalars['ISO8601DateTime']['output'];
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use festivalEditions */
  festivalEdition?: Maybe<FestivalEdition>;
  festivalEditions: Array<FestivalEdition>;
  id: Scalars['ID']['output'];
  isCustomCode: Scalars['Boolean']['output'];
  marketingConsentRequired: Scalars['Boolean']['output'];
  maxUsesCount: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  minPeopleCount?: Maybe<Scalars['Int']['output']>;
  minPeopleCountEnabled: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Order;
  /** @deprecated Use value */
  peopleCount: Scalars['Int']['output'];
  permittedPaymentMethods: Array<PaymentProviderEnum>;
  permittedSources: Array<ReservationSourceEnum>;
  primeTimeIncluded: Scalars['Boolean']['output'];
  singleUse: Scalars['Boolean']['output'];
  slots?: Maybe<Array<Scalars['Int']['output']>>;
  /** TOTAL or PERCENT */
  type: Scalars['String']['output'];
  used: Scalars['Int']['output'];
  user?: Maybe<User>;
  value: Scalars['Float']['output'];
  weekDays: Array<WeekDayEnum>;
};

export type Point = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type PolymorphicCollection = {
  __typename?: 'PolymorphicCollection';
  category: CollectionCategoryEnum;
  country: Country;
  countryCode: Scalars['String']['output'];
  countryId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  elements?: Maybe<Array<CollectionElement>>;
  festivalEdition?: Maybe<FestivalEdition>;
  festivalEditionRestaurants: Array<FestivalEditionRestaurant>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['ID']['output']>;
  reservableExtras?: Maybe<Array<ReservableExtra>>;
  restaurants?: Maybe<Array<Restaurant>>;
  section: Section;
  sectionId: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validFrom: Scalars['ISO8601DateTime']['output'];
  validTo?: Maybe<Scalars['ISO8601DateTime']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type PriceValues = {
  __typename?: 'PriceValues';
  basePrice: Scalars['Float']['output'];
  basePriceWithCrossSells: Scalars['Float']['output'];
  baseUnitPrice?: Maybe<Scalars['Float']['output']>;
  crossSellsPrice: Scalars['Float']['output'];
  currentTenant: Scalars['String']['output'];
  deliveryFee: Scalars['Float']['output'];
  discountValue: Scalars['Float']['output'];
  discountablePrice: Scalars['Float']['output'];
  feeablePeopleCount?: Maybe<Scalars['Int']['output']>;
  originalBaseUnitPrice?: Maybe<Scalars['Float']['output']>;
  priceToPay: Scalars['Float']['output'];
  primeTimeFeeIncludedInBaseUnitPrice?: Maybe<Scalars['Float']['output']>;
  reservableExtrasPrice: Scalars['Float']['output'];
  reservationFee: Scalars['Float']['output'];
  reservationFeeUnitPrice: Scalars['Float']['output'];
  totalValue: Scalars['Float']['output'];
};

/** The connection type for ProductableLogEntry. */
export type ProductableLogEntries = {
  __typename?: 'ProductableLogEntries';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductableLogEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductableLogEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductableLogEntry = {
  __typename?: 'ProductableLogEntry';
  action: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  emailType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  messageId?: Maybe<Scalars['String']['output']>;
  productableId: Scalars['ID']['output'];
  productableType: Scalars['String']['output'];
  recipient?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum ProductableLogEntryActionEnum {
  Cancellation = 'CANCELLATION',
  EmailClicked = 'EMAIL_CLICKED',
  EmailComplained = 'EMAIL_COMPLAINED',
  EmailDelivered = 'EMAIL_DELIVERED',
  EmailEnqueued = 'EMAIL_ENQUEUED',
  EmailFailed = 'EMAIL_FAILED',
  EmailOpened = 'EMAIL_OPENED',
  EmailPermanentFail = 'EMAIL_PERMANENT_FAIL',
  EmailUnsubscribed = 'EMAIL_UNSUBSCRIBED',
  KlarnaNotification = 'KLARNA_NOTIFICATION',
  Refund = 'REFUND',
  SmsEnqueued = 'SMS_ENQUEUED',
  SmsFailed = 'SMS_FAILED',
  SmsSent = 'SMS_SENT',
  StraalNotification = 'STRAAL_NOTIFICATION'
}

/** An edge in a connection. */
export type ProductableLogEntryEdge = {
  __typename?: 'ProductableLogEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductableLogEntry>;
};

/** Autogenerated return type of PurchaseReservation */
export type PurchaseReservationPayload = {
  __typename?: 'PurchaseReservationPayload';
  checkoutPageUrl?: Maybe<Scalars['String']['output']>;
  cryptKey?: Maybe<Scalars['String']['output']>;
  reservation: Reservation;
};

/** Autogenerated return type of PurchaseVoucher */
export type PurchaseVoucherPayload = {
  __typename?: 'PurchaseVoucherPayload';
  checkoutPageUrl?: Maybe<Scalars['String']['output']>;
  cryptKey?: Maybe<Scalars['String']['output']>;
  voucher: Voucher;
};

export type Query = {
  __typename?: 'Query';
  /** List of banners for admin panel */
  adminBanners: Banners;
  adminCollections: Collections;
  /** List of documents for admin */
  adminDocuments: Documents;
  /** Get list of events */
  adminEvents: Events;
  /** List of festival edition restaurants for restaurators and admins */
  adminFestivalEditionRestaurants: FestivalEditionRestaurants;
  adminPageDetails: PageDetails;
  /** List of productable log entries for object */
  adminProductableLogs: ProductableLogEntries;
  adminReservableExtras: ReservableExtras;
  adminReservables: Array<Reservable>;
  adminReservations: Reservations;
  adminReservationsXlsx: Reservations;
  adminRestaurant?: Maybe<Restaurant>;
  adminRestaurants: Restaurants;
  adminReview?: Maybe<Review>;
  adminReviews: Reviews;
  adminSlots: Array<Slot>;
  adminSlotsUsage: Scalars['JSON']['output'];
  adminSponsors: Sponsors;
  adminVoucherSales: Redeemables;
  attachment?: Maybe<ImageFile>;
  attachments: Attachments;
  /** Get single banner */
  banner: Banner;
  /** List of banners */
  banners: Banners;
  calendarRevisionSlots: Array<Slot>;
  calendarRevisions: Array<CalendarRevision>;
  /** Check if card was created */
  checkCard?: Maybe<Card>;
  clientConfigs: ClientConfig;
  /** Get single polymorphic collection */
  collection: PolymorphicCollection;
  /** List of polymorphic collections */
  collections: Collections;
  companyTypes: Array<Company>;
  /** List of festival edition or daily cross sells */
  crossSells: CrossSells;
  currentFestivalEdition?: Maybe<FestivalEdition>;
  currentRestaurant?: Maybe<Restaurant>;
  currentTenant: Scalars['String']['output'];
  dashboard: FestivalEditionData;
  decodeRestaurant: DecodedRestaurant;
  /** Get single discount */
  discount: Redeemable;
  /** List of discounts */
  discounts: Redeemables;
  document: Document;
  /** List of public documents */
  documents: Documents;
  /** Get single event */
  event: Event;
  eventSlotsUsage: Scalars['JSON']['output'];
  /** List of restaurants favorited by current user */
  favoriteRestaurants: Array<Restaurant>;
  festival?: Maybe<Festival>;
  festivalEdition: FestivalEdition;
  festivalEditionRestaurant?: Maybe<FestivalEditionRestaurant>;
  /** List of festival edition restaurants */
  festivalEditionRestaurants: FestivalEditionRestaurants;
  /** List of festival editions */
  festivalEditions: FestivalEditions;
  /** List of festival regions */
  festivalRegions: Array<Region>;
  festivalRegulation?: Maybe<Document>;
  festivals: Array<Festival>;
  findUser?: Maybe<User>;
  geoRegion: Array<Region>;
  googleTranslate: Array<Scalars['String']['output']>;
  incomingEvents: Events;
  /** List of incoming events regions */
  incomingEventsRegions: Array<Region>;
  inventoryItems: InventoryItems;
  manualReservation?: Maybe<ManualReservation>;
  menuPositions: MenuPositions;
  mgmInvitationCode?: Maybe<Scalars['String']['output']>;
  /** List of user discounts */
  myDiscounts: Discounts;
  /** Fetch current user reservations (festival/d2d/event reservations - ONLY PAID) and vouchers (also ONLY PAID) */
  myPurchases: UserPurchases;
  /** List of user reservations */
  myReservations: Reservations;
  /** Nearest slot in given restaurant. People count default equals `2` */
  nearestSlot: Scalars['JSON']['output'];
  ongoingFestivalEditions?: Maybe<Array<FestivalEdition>>;
  /** Get single page detail object */
  pageDetail?: Maybe<PageDetail>;
  payuPaymentMethods: PaymentMethods;
  popularRestaurants: Array<Restaurant>;
  popularTags: Array<Tag>;
  randomIncomingEvent?: Maybe<Event>;
  /** List of regions */
  regions: Array<Region>;
  /** Regulations for festival edition */
  regulations: Array<Document>;
  reservable: Reservable;
  reservableExtra: ReservableExtra;
  reservableExtras: ReservableExtras;
  reservables: Array<Reservable>;
  /** Get info about reservation */
  reservation?: Maybe<Reservation>;
  reservationCancellationDeadline?: Maybe<Scalars['Date']['output']>;
  reservationPossibility: ReservationPossibility;
  /** Resolves slug to attachable object */
  resolveUrlSlug?: Maybe<UrlResolverResult>;
  restaurant?: Maybe<Restaurant>;
  restaurantLink: Scalars['String']['output'];
  /** Get all restaurant reservations for date */
  restaurantReservations: Array<BookReservation>;
  restaurants: Restaurants;
  rules: Array<Rule>;
  /** Full text search for tags, collections or restaurants */
  search: Array<SearchResult>;
  /** List of sections */
  sections: Sections;
  slotDiscountRule: SlotDiscountRule;
  /** List of SlotDiscountRules */
  slotDiscountRules: SlotDiscountRules;
  slots: Array<Slot>;
  slotsUsage: Scalars['JSON']['output'];
  sponsor?: Maybe<Sponsor>;
  sponsors: Sponsors;
  strapi: Scalars['JSON']['output'];
  submissions: Submissions;
  /** List of tags */
  tags: Array<Tag>;
  user?: Maybe<User>;
  /** List of users */
  users: Users;
  /** Find a voucher by code */
  voucher?: Maybe<Voucher>;
};


export type QueryAdminBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  section_slug?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAdminCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<CollectionCategoryEnum>;
  country_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  section_slug?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAdminDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DocumentTypeEnum>;
};


export type QueryAdminEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<EventOrder>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  restaurant_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  slotless?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
};


export type QueryAdminFestivalEditionRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<StatusableEnum>>;
};


export type QueryAdminPageDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminProductableLogsArgs = {
  action?: InputMaybe<Array<ProductableLogEntryActionEnum>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productableId?: InputMaybe<Scalars['Int']['input']>;
  productableType?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminReservableExtrasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  date_range?: InputMaybe<DateRange>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  restaurantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  restaurant_tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  slot_range?: InputMaybe<SlotRange>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryAdminReservablesArgs = {
  dates: DateRange;
  restaurantId: Scalars['ID']['input'];
};


export type QueryAdminReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  changed_since?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  date_range?: InputMaybe<DateRange>;
  event_id?: InputMaybe<Scalars['ID']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdminReservationsOrderBy>>;
  reservable_type?: InputMaybe<ReservableEnum>;
  restaurant_id?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slot_range?: InputMaybe<SlotRange>;
  status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['ID']['input']>;
  voucher_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminReservationsXlsxArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  changed_since?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  date_range?: InputMaybe<DateRange>;
  event_id?: InputMaybe<Scalars['ID']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdminReservationsOrderBy>>;
  reservable_type?: InputMaybe<ReservableEnum>;
  restaurant_id?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slot_range?: InputMaybe<SlotRange>;
  status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['ID']['input']>;
  voucher_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminRestaurantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collection_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  festival_edition_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  in_area?: InputMaybe<Area>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<RestaurantsOrderBy>>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  reservables?: InputMaybe<Array<ReservableInput>>;
  reservation_filters?: InputMaybe<ReservationFilter>;
  sort?: InputMaybe<RestaurantSort>;
  tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  with_reservable_extras?: InputMaybe<Scalars['Boolean']['input']>;
  zone_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminReviewArgs = {
  review_id: Scalars['ID']['input'];
};


export type QueryAdminReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReviewOrder>;
  restaurant_id?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ReviewStatusEnum>>;
  type?: InputMaybe<Array<ReservableEnum>>;
};


export type QueryAdminSlotsArgs = {
  dates: DateRange;
  reservables: Array<ReservableInput>;
  withReservableExtra?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAdminSlotsUsageArgs = {
  dateRange?: InputMaybe<DateRange>;
  restaurantId: Scalars['ID']['input'];
};


export type QueryAdminSponsorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  clubSponsors?: InputMaybe<Scalars['Boolean']['input']>;
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminVoucherSalesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<DateRange>;
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<AdminVoucherSalesOrder>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatusEnum>;
};


export type QueryAttachmentArgs = {
  attachment_id: Scalars['ID']['input'];
};


export type QueryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  restaurant_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryBannerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  section_slug?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCalendarRevisionSlotsArgs = {
  revisionId: Scalars['ID']['input'];
};


export type QueryCalendarRevisionsArgs = {
  restaurantId: Scalars['ID']['input'];
};


export type QueryCheckCardArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<CollectionCategoryEnum>;
  country_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  section_slug?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCrossSellsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  daily_id?: InputMaybe<Scalars['ID']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrossSellsOrderBy>>;
};


export type QueryCurrentFestivalEditionArgs = {
  code?: InputMaybe<FestivalCodesEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDashboardArgs = {
  festivalEditionId: Scalars['ID']['input'];
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDecodeRestaurantArgs = {
  code: Scalars['ID']['input'];
};


export type QueryDiscountArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<DateRange>;
  discountable_id?: InputMaybe<Scalars['ID']['input']>;
  discountable_type?: InputMaybe<DiscountableTypeEnum>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  event_id?: InputMaybe<Scalars['ID']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<DiscountOrder>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DiscountTypes>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DocumentTypeEnum>;
};


export type QueryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryEventSlotsUsageArgs = {
  dateRange?: InputMaybe<DateRange>;
  eventId: Scalars['ID']['input'];
};


export type QueryFestivalArgs = {
  festivalId: Scalars['ID']['input'];
};


export type QueryFestivalEditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFestivalEditionRestaurantArgs = {
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFestivalEditionRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival_edition_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  reservation_filters?: InputMaybe<ReservationFilter>;
  tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  zone_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFestivalEditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  festival_id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FestivalEditionsOrderBy>>;
  restaurant_id?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Array<FestivalStateEnum>>;
  timing?: InputMaybe<FestivalTimingEnum>;
  with_hidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFestivalRegionsArgs = {
  festivalEditionId: Scalars['ID']['input'];
};


export type QueryFestivalRegulationArgs = {
  festivalEditionId: Scalars['ID']['input'];
};


export type QueryFestivalsArgs = {
  orderBy?: InputMaybe<Array<FestivalsOrderBy>>;
};


export type QueryFindUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGeoRegionArgs = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};


export type QueryGoogleTranslateArgs = {
  contents: Array<Scalars['String']['input']>;
  langFrom: Scalars['String']['input'];
  langTo: Scalars['String']['input'];
};


export type QueryIncomingEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<EventOrder>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  restaurant_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  slotless?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
};


export type QueryIncomingEventsRegionsArgs = {
  slotless?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInventoryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryManualReservationArgs = {
  code: Scalars['String']['input'];
};


export type QueryMenuPositionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festivalEditionId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  regionId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyPurchasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  festival?: InputMaybe<FestivalCodesEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reservable_type?: InputMaybe<ReservableEnum>;
  type?: InputMaybe<MyReservationsTypeEnum>;
};


export type QueryNearestSlotArgs = {
  festivalEditionId: Scalars['ID']['input'];
  peopleCount?: InputMaybe<Scalars['Int']['input']>;
  restaurantId: Scalars['ID']['input'];
};


export type QueryOngoingFestivalEditionsArgs = {
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  withVoucher?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPageDetailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPopularRestaurantsArgs = {
  region_id: Scalars['ID']['input'];
};


export type QueryPopularTagsArgs = {
  region_id: Scalars['ID']['input'];
};


export type QueryRandomIncomingEventArgs = {
  region_id?: InputMaybe<Scalars['ID']['input']>;
  slotless?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
};


export type QueryRegionsArgs = {
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRegulationsArgs = {
  festivalEditionId: Scalars['ID']['input'];
};


export type QueryReservableArgs = {
  id: Scalars['ID']['input'];
  type: ReservableEnum;
};


export type QueryReservableExtraArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReservableExtrasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  date_range?: InputMaybe<DateRange>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  restaurantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  restaurant_tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  slot_range?: InputMaybe<SlotRange>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryReservablesArgs = {
  dates: DateRange;
  restaurantId: Scalars['ID']['input'];
};


export type QueryReservationArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  securityToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReservationCancellationDeadlineArgs = {
  code: Scalars['String']['input'];
};


export type QueryReservationPossibilityArgs = {
  approxStartAt: Scalars['Int']['input'];
  date: Scalars['Date']['input'];
  isDefinitive?: InputMaybe<Scalars['Boolean']['input']>;
  num_people: Scalars['Int']['input'];
  reservable_id: Scalars['ID']['input'];
  reservation_id?: InputMaybe<Scalars['ID']['input']>;
  restaurant_id: Scalars['ID']['input'];
};


export type QueryResolveUrlSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryRestaurantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRestaurantLinkArgs = {
  restaurant_id: Scalars['ID']['input'];
};


export type QueryRestaurantReservationsArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collection_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  festival_edition_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  in_area?: InputMaybe<Area>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<RestaurantsOrderBy>>;
  region_id?: InputMaybe<Scalars['ID']['input']>;
  reservables?: InputMaybe<Array<ReservableInput>>;
  reservation_filters?: InputMaybe<ReservationFilter>;
  sort?: InputMaybe<RestaurantSort>;
  tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<TagFilter>>>;
  with_reservable_extras?: InputMaybe<Scalars['Boolean']['input']>;
  zone_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRulesArgs = {
  dates?: InputMaybe<DateRange>;
  restaurantId: Scalars['ID']['input'];
};


export type QuerySearchArgs = {
  regionId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};


export type QuerySectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySlotDiscountRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlotDiscountRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySlotsArgs = {
  dates: DateRange;
  reservables: Array<ReservableInput>;
  withReservableExtra?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySlotsUsageArgs = {
  dateRange?: InputMaybe<DateRange>;
  restaurantId: Scalars['ID']['input'];
};


export type QuerySponsorArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySponsorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  clubSponsors?: InputMaybe<Scalars['Boolean']['input']>;
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<SponsorsOrderBy>>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStrapiArgs = {
  query: Scalars['String']['input'];
};


export type QuerySubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubmissionsOrderBy>>;
};


export type QueryTagsArgs = {
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  role?: InputMaybe<RoleEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVoucherArgs = {
  code: Scalars['String']['input'];
};

export type RatingAttributes = {
  atmosphere?: InputMaybe<Scalars['Int']['input']>;
  delivery?: InputMaybe<Scalars['Int']['input']>;
  dessert?: InputMaybe<Scalars['Int']['input']>;
  mainDish?: InputMaybe<Scalars['Int']['input']>;
  meals?: InputMaybe<Scalars['Int']['input']>;
  menu?: InputMaybe<MenuKindEnum>;
  package?: InputMaybe<Scalars['Int']['input']>;
  sideDish?: InputMaybe<Scalars['Int']['input']>;
  staff?: InputMaybe<Scalars['Int']['input']>;
};

export type RatingData = {
  __typename?: 'RatingData';
  atmosphere?: Maybe<Scalars['Int']['output']>;
  currentTenant: Scalars['String']['output'];
  delivery?: Maybe<Scalars['Int']['output']>;
  dessert?: Maybe<Scalars['Int']['output']>;
  mainDish?: Maybe<Scalars['Int']['output']>;
  meals?: Maybe<Scalars['Int']['output']>;
  menu?: Maybe<MenuKindEnum>;
  menuRating?: Maybe<Scalars['Int']['output']>;
  package?: Maybe<Scalars['Int']['output']>;
  sideDish?: Maybe<Scalars['Int']['output']>;
  staff?: Maybe<Scalars['Int']['output']>;
};

/** Something able to be exchanged for goods, a discount, or money */
export type Redeemable = {
  billingMethod: DiscountBillingMethodEnum;
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  discountAssignments: Array<DiscountAssignment>;
  enabled: Scalars['Boolean']['output'];
  events: Array<FestivalEdition>;
  expired: Scalars['ISO8601DateTime']['output'];
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use festivalEditions */
  festivalEdition?: Maybe<FestivalEdition>;
  festivalEditions: Array<FestivalEdition>;
  id: Scalars['ID']['output'];
  isCustomCode: Scalars['Boolean']['output'];
  marketingConsentRequired: Scalars['Boolean']['output'];
  maxUsesCount: Scalars['Int']['output'];
  minPeopleCount?: Maybe<Scalars['Int']['output']>;
  minPeopleCountEnabled: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Order;
  /** @deprecated Use value */
  peopleCount: Scalars['Int']['output'];
  permittedPaymentMethods: Array<PaymentProviderEnum>;
  permittedSources: Array<ReservationSourceEnum>;
  primeTimeIncluded: Scalars['Boolean']['output'];
  singleUse: Scalars['Boolean']['output'];
  slots?: Maybe<Array<Scalars['Int']['output']>>;
  /** TOTAL or PERCENT */
  type: Scalars['String']['output'];
  used: Scalars['Int']['output'];
  user?: Maybe<User>;
  value: Scalars['Float']['output'];
  weekDays: Array<WeekDayEnum>;
};

/** An edge in a connection. */
export type RedeemableEdge = {
  __typename?: 'RedeemableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Redeemable>;
};

/** The connection type for Redeemable. */
export type Redeemables = {
  __typename?: 'Redeemables';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RedeemableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Redeemable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Region = {
  __typename?: 'Region';
  currentTenant: Scalars['String']['output'];
  eventsEnabled: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  zones: Array<Zone>;
};

export enum RegulationStatusEnum {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  NotAccepted = 'NOT_ACCEPTED'
}

export type Reservable = Daily | Event | FestivalEditionRestaurant;

export enum ReservableEnum {
  Daily = 'Daily',
  Event = 'Event',
  FestivalEditionRestaurant = 'FestivalEditionRestaurant'
}

export type ReservableExtra = {
  __typename?: 'ReservableExtra';
  currentTenant: Scalars['String']['output'];
  defaultReservableFrom: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  eatingDuration: Scalars['Int']['output'];
  editableByRestaurateur: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageFile>;
  menus?: Maybe<Array<Menu>>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  promoted: Scalars['Boolean']['output'];
  reservableFrom: Scalars['ISO8601DateTime']['output'];
  restaurant: Restaurant;
  rules: Array<ReservableExtraRule>;
  slug: Scalars['String']['output'];
  /** @deprecated There will be only one type of RE */
  type: Scalars['String']['output'];
};

export type ReservableExtraAssignment = {
  __typename?: 'ReservableExtraAssignment';
  currentTenant: Scalars['String']['output'];
  menu: Menu;
  menuId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  reservableExtra: ReservableExtra;
  reservableExtraId: Scalars['ID']['output'];
};

export type ReservableExtraAttrInput = {
  menuId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  reservableExtraId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type ReservableExtraEdge = {
  __typename?: 'ReservableExtraEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReservableExtra>;
};

export type ReservableExtraRule = {
  __typename?: 'ReservableExtraRule';
  active: Scalars['Boolean']['output'];
  currentTenant: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  endsAt: Scalars['Int']['output'];
  endsOn?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  startsAt: Scalars['Int']['output'];
  startsOn: Scalars['Date']['output'];
  weekDays?: Maybe<Array<WeekDayEnum>>;
  weekdaysFlags: Scalars['Int']['output'];
};

/** The connection type for ReservableExtra. */
export type ReservableExtras = {
  __typename?: 'ReservableExtras';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReservableExtraEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReservableExtra>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReservableInput = {
  id: Scalars['ID']['input'];
  type: ReservableEnum;
};

export type Reservation = {
  __typename?: 'Reservation';
  adminNotes?: Maybe<Scalars['String']['output']>;
  blikDiscountValue: Scalars['Int']['output'];
  canBeCancelled: Scalars['Boolean']['output'];
  canBeUpdated: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  deliveryAddress?: Maybe<DeliveryAddress>;
  discount?: Maybe<Redeemable>;
  email?: Maybe<Scalars['String']['output']>;
  expired: Scalars['Boolean']['output'];
  expiresAt: Scalars['ISO8601DateTime']['output'];
  festivalDrinkRequiredQuantity: Scalars['Int']['output'];
  guestInvitationUrl?: Maybe<Scalars['String']['output']>;
  hall?: Maybe<Scalars['String']['output']>;
  hasReservableExtra: Scalars['Boolean']['output'];
  hour: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastOrderPrice?: Maybe<Scalars['Float']['output']>;
  logEntries?: Maybe<Array<ProductableLogEntry>>;
  menus: Array<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  passed: Scalars['Boolean']['output'];
  paymentMethod: Scalars['String']['output'];
  peopleCount: Scalars['Int']['output'];
  permittedPaymentMethods: Array<PaymentProviderEnum>;
  previousReservation?: Maybe<Reservation>;
  priceValues: PriceValues;
  reservable?: Maybe<Reservable>;
  reservableExtraAssignments?: Maybe<Array<ReservableExtraAssignment>>;
  reservableExtras: Array<ReservableExtra>;
  reservableExtrasAvailable: Scalars['Boolean']['output'];
  reservationCrossSells?: Maybe<Array<ReservationCrossSell>>;
  reservationEditUrl?: Maybe<Scalars['String']['output']>;
  reservationGuests: Array<ReservationGuest>;
  reservationType: ReservationTypeEnum;
  restauratorNotes?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Review>;
  showBlik: Scalars['Boolean']['output'];
  slot: Scalars['Int']['output'];
  slotDiscountValue: Scalars['Int']['output'];
  status: OrderStatusEnum;
  statusDetails?: Maybe<OrderStatusDetailsEnum>;
  table?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type ReservationClosingRule = {
  __typename?: 'ReservationClosingRule';
  currentTenant: Scalars['String']['output'];
  daysOffset: Scalars['Int']['output'];
  minutesSinceMidnight: Scalars['Int']['output'];
};

export type ReservationCrossSell = {
  __typename?: 'ReservationCrossSell';
  crossSell: CrossSell;
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isApplicable: Scalars['Boolean']['output'];
  quantity: Scalars['Int']['output'];
  reservation: Reservation;
};

export type ReservationFilter = {
  endsOn: Scalars['Date']['input'];
  hours: Array<Scalars['Int']['input']>;
  peopleCount: Scalars['Int']['input'];
  reservableType?: InputMaybe<ReservableEnum>;
  startsOn: Scalars['Date']['input'];
};

export type ReservationGuest = {
  __typename?: 'ReservationGuest';
  confirmedAt?: Maybe<Scalars['Date']['output']>;
  currentTenant: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reservationId: Scalars['ID']['output'];
  user?: Maybe<User>;
};

export type ReservationHourAvailability = {
  __typename?: 'ReservationHourAvailability';
  available: Scalars['Boolean']['output'];
  currentTenant: Scalars['String']['output'];
  hour: Scalars['String']['output'];
  hourAsSlot: Scalars['Int']['output'];
};

export enum ReservationNotificationTypeEnum {
  UserConfirmationEmail = 'USER_CONFIRMATION_EMAIL'
}

export type ReservationPossibility = {
  __typename?: 'ReservationPossibility';
  availableReservableExtras: Array<ReservableExtra>;
  currentTenant: Scalars['String']['output'];
  hoursAvailability: Array<ReservationHourAvailability>;
  reservation: Reservation;
  reservationPossible: Scalars['Boolean']['output'];
};

export enum ReservationSourceEnum {
  Android = 'ANDROID',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Instagram = 'INSTAGRAM',
  Ios = 'IOS',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Phone = 'PHONE',
  Web = 'WEB'
}

export enum ReservationTypeEnum {
  Delivery = 'DELIVERY',
  Onsite = 'ONSITE',
  TakeAway = 'TAKE_AWAY'
}

/** The connection type for Reservation. */
export type Reservations = {
  __typename?: 'Reservations';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReservationsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Reservation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReservationsData = {
  __typename?: 'ReservationsData';
  allocationsCount: Scalars['Int']['output'];
  currentTenant: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  dayOfWeek: Scalars['Int']['output'];
  income: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  peopleCount: Scalars['Int']['output'];
  reservationCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReservationsEdge = {
  __typename?: 'ReservationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Reservation>;
};

/** Autogenerated return type of ResetPasswordRequest */
export type ResetPasswordRequestPayload = {
  __typename?: 'ResetPasswordRequestPayload';
  sent: Scalars['Boolean']['output'];
};

export type Restaurant = {
  __typename?: 'Restaurant';
  additionalEmail?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  billingData?: Maybe<RestaurantBillingData>;
  capacity?: Maybe<Scalars['Int']['output']>;
  chef?: Maybe<Scalars['String']['output']>;
  chefSocialMediaUrl?: Maybe<Scalars['String']['output']>;
  clubAgreement?: Maybe<Agreement>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  establishmentYear?: Maybe<Scalars['Int']['output']>;
  externalSourceUrls: Array<RestaurantExternalSourceUrl>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  favorited: Scalars['Boolean']['output'];
  festivalEditionRestaurants: Array<FestivalEditionRestaurant>;
  forceClubAgreement: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  hours: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageFiles: Array<ImageFile>;
  incomingChanges?: Maybe<RestaurantChanges>;
  instagramUrl?: Maybe<Scalars['String']['output']>;
  interiorDesigner?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  menuFile?: Maybe<PdfFile>;
  menuFileUrl?: Maybe<Scalars['String']['output']>;
  metadatas: Array<Metadata>;
  name: Scalars['String']['output'];
  nearestFestivalEdition?: Maybe<FestivalEdition>;
  notificationNumber?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Array<Hours>>;
  openingHoursToday?: Maybe<Hours>;
  openingState: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  ratingCount: Scalars['Int']['output'];
  region: Region;
  reservableExtras: Array<ReservableExtra>;
  reservables: Array<Reservable>;
  restaurantDeliveryZones: Array<RestaurantDeliveryZone>;
  restaurateurSocialMediaUrl?: Maybe<Scalars['String']['output']>;
  restaurator?: Maybe<Scalars['String']['output']>;
  restaurators: Array<User>;
  reviews: Array<Review>;
  salesforceId?: Maybe<Scalars['ID']['output']>;
  slotDuration: Scalars['Int']['output'];
  slots: Array<SlotDefinition>;
  slug: Scalars['String']['output'];
  status: Scalars['String']['output'];
  submission?: Maybe<Submission>;
  tables: Array<Table>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  urls?: Maybe<Array<RestaurantUrl>>;
  userAgreedToReceiveMarketing?: Maybe<Scalars['Boolean']['output']>;
  validClubAgreement: Scalars['Boolean']['output'];
  webUrl?: Maybe<Scalars['String']['output']>;
  zone: Zone;
};


export type RestaurantHoursArgs = {
  weekDay: Scalars['Int']['input'];
};


export type RestaurantReservableExtrasArgs = {
  visible?: InputMaybe<Scalars['String']['input']>;
};

export type RestaurantBillingData = {
  __typename?: 'RestaurantBillingData';
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<CompanyTypeEnum>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  representName?: Maybe<Scalars['String']['output']>;
  restaurant?: Maybe<Restaurant>;
  street?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
};

export type RestaurantChanges = {
  __typename?: 'RestaurantChanges';
  additionalEmail?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  chef?: Maybe<Scalars['String']['output']>;
  chefSocialMediaUrl?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  establishmentYear?: Maybe<Scalars['Int']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  imageFiles?: Maybe<Array<ImageFile>>;
  instagramUrl?: Maybe<Scalars['String']['output']>;
  interiorDesigner?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Renamed to capacity */
  maxPeopleCount?: Maybe<Scalars['Int']['output']>;
  menuFile?: Maybe<PdfFile>;
  name?: Maybe<Scalars['String']['output']>;
  notificationNumber?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Array<OpeningHoursChanges>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  restaurantBillingData?: Maybe<BillingDataChanges>;
  restaurantDeliveryZones?: Maybe<Array<RestaurantDeliveryZoneChanges>>;
  restaurateurSocialMediaUrl?: Maybe<Scalars['String']['output']>;
  restaurator?: Maybe<Scalars['String']['output']>;
  tables?: Maybe<Array<TableChanges>>;
  tagIds?: Maybe<Array<Scalars['ID']['output']>>;
  zone?: Maybe<Zone>;
};

export type RestaurantDeliveryZone = {
  __typename?: 'RestaurantDeliveryZone';
  color?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  deliveryRadius: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type RestaurantDeliveryZoneChanges = {
  __typename?: 'RestaurantDeliveryZoneChanges';
  color?: Maybe<Scalars['String']['output']>;
  currentTenant: Scalars['String']['output'];
  deliveryRadius: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type RestaurantExternalSourceUrl = {
  __typename?: 'RestaurantExternalSourceUrl';
  currentTenant: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum RestaurantOrderField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export enum RestaurantSort {
  Distance = 'DISTANCE',
  MostScored = 'MOST_SCORED',
  Position = 'POSITION',
  Rating = 'RATING'
}

export type RestaurantUrl = {
  __typename?: 'RestaurantUrl';
  currentTenant: Scalars['String']['output'];
  restaurant: Restaurant;
  url: Scalars['String']['output'];
  urlType: RestaurantUrlTypeEnum;
};

export enum RestaurantUrlTypeEnum {
  ChefSocialMedia = 'CHEF_SOCIAL_MEDIA',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Instagram = 'INSTAGRAM',
  RestaurateurSocialMedia = 'RESTAURATEUR_SOCIAL_MEDIA',
  Tiktok = 'TIKTOK',
  TripAdvisor = 'TRIP_ADVISOR',
  Website = 'WEBSITE'
}

/** The connection type for Restaurant. */
export type Restaurants = {
  __typename?: 'Restaurants';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RestaurantsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Restaurant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RestaurantsEdge = {
  __typename?: 'RestaurantsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Restaurant>;
};

export type RestaurantsOrderBy = {
  direction: OrderDirection;
  field: RestaurantOrderField;
};

export type Review = {
  __typename?: 'Review';
  /** @deprecated Deprecated */
  atmosphereRating?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  liked: Scalars['Boolean']['output'];
  /** @deprecated Deprecated */
  menuRating?: Maybe<Scalars['Int']['output']>;
  moderatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rating: Scalars['Int']['output'];
  ratings: RatingData;
  reservation: Reservation;
  /** @deprecated Warning, this will be deprecated */
  restaurant: Restaurant;
  /** @deprecated Deprecated */
  staffRating?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ReviewStatusEnum>;
  text?: Maybe<Scalars['String']['output']>;
  user: User;
};

export enum ReviewOrder {
  Recent = 'RECENT'
}

export enum ReviewStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** The connection type for Review. */
export type Reviews = {
  __typename?: 'Reviews';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Review>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReviewsEdge = {
  __typename?: 'ReviewsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Review>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Restaurator = 'RESTAURATOR',
  User = 'USER'
}

export type Rule = {
  __typename?: 'Rule';
  active: Scalars['Boolean']['output'];
  currentTenant: Scalars['String']['output'];
  endsOn: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  reservable: Reservable;
  slots: Array<Scalars['Int']['output']>;
  startsOn: Scalars['Date']['output'];
  weekDays?: Maybe<Array<WeekDay>>;
};

/** Objects which may be returned by search query */
export type SearchResult = Collection | Restaurant | Tag;

export type SeatsData = {
  __typename?: 'SeatsData';
  allocCapacityPercent: Scalars['Float']['output'];
  currentTenant: Scalars['String']['output'];
  freeSeatsCount: Scalars['Int']['output'];
  maximalCapacity: Scalars['Int']['output'];
  seatsCount: Scalars['Int']['output'];
  soldCapacityRatio: Scalars['Float']['output'];
  takenSeatsCount: Scalars['Int']['output'];
};

export type Section = {
  __typename?: 'Section';
  banners: Array<Banner>;
  collections: Array<PolymorphicCollection>;
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** An edge in a connection. */
export type SectionEdge = {
  __typename?: 'SectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Section>;
};

/** The connection type for Section. */
export type Sections = {
  __typename?: 'Sections';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Section>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Session = {
  __typename?: 'Session';
  clientToken: Scalars['String']['output'];
  currentTenant: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  paymentMethodCategories?: Maybe<Scalars['JSON']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of SignIn */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Slot = {
  __typename?: 'Slot';
  allocated: Scalars['Int']['output'];
  currentTenant: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  discount: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interval: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  primeTimeFee?: Maybe<Scalars['Float']['output']>;
  reservable?: Maybe<Reservable>;
  runningOut: Scalars['Boolean']['output'];
  sizes: Array<Scalars['Int']['output']>;
  slot: Scalars['Int']['output'];
  used: Scalars['Int']['output'];
  voucherRequired: Scalars['Boolean']['output'];
};

export type SlotDefinition = {
  __typename?: 'SlotDefinition';
  currentTenant: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  hours: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  interval: Scalars['Int']['output'];
  possibleSlots: Array<Scalars['Int']['output']>;
  slotEnd: Scalars['Int']['output'];
  slotStart: Scalars['Int']['output'];
  slotable: Slotable;
  weekDay?: Maybe<Scalars['Int']['output']>;
};

export type SlotDiscountRule = {
  __typename?: 'SlotDiscountRule';
  currentTenant: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  enabled: Scalars['Boolean']['output'];
  endsOn?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  slotEnd?: Maybe<Scalars['Int']['output']>;
  slotStart?: Maybe<Scalars['Int']['output']>;
  startsOn?: Maybe<Scalars['Date']['output']>;
};

/** The connection type for SlotDiscountRule. */
export type SlotDiscountRules = {
  __typename?: 'SlotDiscountRules';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlotDiscountRulesEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SlotDiscountRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SlotDiscountRulesEdge = {
  __typename?: 'SlotDiscountRulesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SlotDiscountRule>;
};

export type SlotRange = {
  slotFrom: Scalars['Int']['input'];
  slotTo: Scalars['Int']['input'];
};

/** Slot Definition can be assigned to FestivalEdition, Restaurant or Event */
export type Slotable = Daily | Event | FestivalEdition | Restaurant;

export type SlotsData = {
  __typename?: 'SlotsData';
  allocationsCount: Scalars['Int']['output'];
  currentTenant: Scalars['String']['output'];
  dayOfWeek: Scalars['Int']['output'];
  peopleCount: Scalars['Int']['output'];
  reservationsCount: Scalars['Int']['output'];
  slot: Scalars['Int']['output'];
};

export type Sponsor = {
  __typename?: 'Sponsor';
  currentTenant: Scalars['String']['output'];
  festivalEditions?: Maybe<Array<FestivalEdition>>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageFile>;
  kind: SponsorKindsEnum;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  region?: Maybe<Region>;
  subkind?: Maybe<SponsorSubkindsEnum>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum SponsorKindsEnum {
  Main = 'MAIN',
  Partner = 'PARTNER',
  Patron = 'PATRON',
  PlebiscitePartner = 'PLEBISCITE_PARTNER',
  Regional = 'REGIONAL'
}

export enum SponsorOrderField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export enum SponsorSubkindsEnum {
  Aerial = 'AERIAL',
  Cocktail = 'COCKTAIL',
  Culinary = 'CULINARY',
  Edition = 'EDITION',
  Logistical = 'LOGISTICAL',
  Media = 'MEDIA',
  Regular = 'REGULAR'
}

/** The connection type for Sponsor. */
export type Sponsors = {
  __typename?: 'Sponsors';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SponsorsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sponsor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SponsorsEdge = {
  __typename?: 'SponsorsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Sponsor>;
};

export type SponsorsOrderBy = {
  direction: OrderDirection;
  field: SponsorOrderField;
};

export enum StatusableEnum {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING'
}

export type Submission = {
  __typename?: 'Submission';
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  submissionable: Submissionable;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum SubmissionOrderField {
  CreatedAt = 'CREATED_AT'
}

/** Objects which may be referenced submissionable of submission */
export type Submissionable = FestivalEditionRestaurant | Restaurant;

/** The connection type for Submission. */
export type Submissions = {
  __typename?: 'Submissions';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubmissionsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Submission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubmissionsEdge = {
  __typename?: 'SubmissionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Submission>;
};

export type SubmissionsOrderBy = {
  direction: OrderDirection;
  field: SubmissionOrderField;
};

export type Table = {
  __typename?: 'Table';
  amount: Scalars['Int']['output'];
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isModular: Scalars['Boolean']['output'];
  peopleCount: Scalars['Int']['output'];
  restaurant: Restaurant;
};

export type TableChanges = {
  __typename?: 'TableChanges';
  amount?: Maybe<Scalars['Int']['output']>;
  currentTenant: Scalars['String']['output'];
  peopleCount?: Maybe<Scalars['Int']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  currentTenant: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<TagIconEnum>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  tagCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type TagFilter = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export enum TagIconEnum {
  IconFoodRestrictionFish = 'icon_food_restriction_fish',
  IconFoodRestrictionFlexitarian = 'icon_food_restriction_flexitarian',
  IconFoodRestrictionGeneric = 'icon_food_restriction_generic',
  IconFoodRestrictionGlutenFree = 'icon_food_restriction_gluten_free',
  IconFoodRestrictionHealthy = 'icon_food_restriction_healthy',
  IconFoodRestrictionLactoseFree = 'icon_food_restriction_lactose_free',
  IconFoodRestrictionVegan = 'icon_food_restriction_vegan',
  IconFoodRestrictionVege = 'icon_food_restriction_vege'
}

export type UrlResolverResult = {
  __typename?: 'UrlResolverResult';
  currentTenant: Scalars['String']['output'];
  defaultSlug?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['ID']['output'];
  objectType: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accessLevel: RoleEnum;
  agreedForMarketingFromRestaurant: Scalars['Boolean']['output'];
  anonymous: Scalars['Boolean']['output'];
  blocked: Scalars['Boolean']['output'];
  card?: Maybe<Card>;
  cards?: Maybe<Array<Card>>;
  currentTenant: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasMarketingConsent: Scalars['Boolean']['output'];
  hasPernodConsent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  metadatas: Array<Metadata>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']['output']>;
  regions: Array<Region>;
  regulationStatus: RegulationStatusEnum;
  restaurants: Array<Restaurant>;
  /** @deprecated Use access_level instead. */
  role: Scalars['String']['output'];
  salesmanagoContactId?: Maybe<Scalars['String']['output']>;
  subscribed: Scalars['Boolean']['output'];
  verified: Scalars['Boolean']['output'];
};


export type UserAgreedForMarketingFromRestaurantArgs = {
  restaurantId: Scalars['ID']['input'];
};

export type UserAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UserConsentInput = {
  consentSubtype?: InputMaybe<UserConsentSubtypeEnum>;
  consentType: UserConsentTypeEnum;
  given: Scalars['Boolean']['input'];
};

export enum UserConsentSubtypeEnum {
  Pernod = 'PERNOD'
}

export enum UserConsentTypeEnum {
  MarketingConsent = 'MARKETING_CONSENT',
  PartnerConsent = 'PARTNER_CONSENT',
  RcRegulationConsent = 'RC_REGULATION_CONSENT',
  RestaurantMarketingConsent = 'RESTAURANT_MARKETING_CONSENT'
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export enum UserOrderField {
  CreatedAt = 'CREATED_AT'
}

export type UserPurchase = PercentageCoupon | Reservation | ValueCoupon | Voucher;

/** The connection type for UserPurchase. */
export type UserPurchases = {
  __typename?: 'UserPurchases';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserPurchasesEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserPurchase>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserPurchasesEdge = {
  __typename?: 'UserPurchasesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserPurchase>;
};

/** The connection type for User. */
export type Users = {
  __typename?: 'Users';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UsersData = {
  __typename?: 'UsersData';
  currentTenant: Scalars['String']['output'];
  newUsersCount: Scalars['Int']['output'];
  oldUsersCount: Scalars['Int']['output'];
  usersCount: Scalars['Int']['output'];
};

export type UsersOrderBy = {
  direction: OrderDirection;
  field: UserOrderField;
};

export type ValueCoupon = Redeemable & {
  __typename?: 'ValueCoupon';
  billingMethod: DiscountBillingMethodEnum;
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  discountAssignments: Array<DiscountAssignment>;
  enabled: Scalars['Boolean']['output'];
  events: Array<FestivalEdition>;
  expired: Scalars['ISO8601DateTime']['output'];
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use festivalEditions */
  festivalEdition?: Maybe<FestivalEdition>;
  festivalEditions: Array<FestivalEdition>;
  id: Scalars['ID']['output'];
  isCustomCode: Scalars['Boolean']['output'];
  marketingConsentRequired: Scalars['Boolean']['output'];
  maxUsesCount: Scalars['Int']['output'];
  metadatas: Array<Metadata>;
  minPeopleCount?: Maybe<Scalars['Int']['output']>;
  minPeopleCountEnabled: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Order;
  /** @deprecated Use value */
  peopleCount: Scalars['Int']['output'];
  permittedPaymentMethods: Array<PaymentProviderEnum>;
  permittedSources: Array<ReservationSourceEnum>;
  primeTimeIncluded: Scalars['Boolean']['output'];
  singleUse: Scalars['Boolean']['output'];
  slots?: Maybe<Array<Scalars['Int']['output']>>;
  /** TOTAL or PERCENT */
  type: Scalars['String']['output'];
  used: Scalars['Int']['output'];
  user?: Maybe<User>;
  value: Scalars['Float']['output'];
  weekDays: Array<WeekDayEnum>;
};

export type Voucher = Redeemable & {
  __typename?: 'Voucher';
  billingMethod: DiscountBillingMethodEnum;
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentTenant: Scalars['String']['output'];
  discountAssignments: Array<DiscountAssignment>;
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  events: Array<FestivalEdition>;
  expired: Scalars['ISO8601DateTime']['output'];
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Use festivalEditions */
  festivalEdition?: Maybe<FestivalEdition>;
  festivalEditions: Array<FestivalEdition>;
  id: Scalars['ID']['output'];
  isCustomCode: Scalars['Boolean']['output'];
  marketingConsentRequired: Scalars['Boolean']['output'];
  maxUsesCount: Scalars['Int']['output'];
  minPeopleCount?: Maybe<Scalars['Int']['output']>;
  minPeopleCountEnabled: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: Order;
  peopleCount: Scalars['Int']['output'];
  permittedPaymentMethods: Array<PaymentProviderEnum>;
  permittedSources: Array<ReservationSourceEnum>;
  primeTimeIncluded: Scalars['Boolean']['output'];
  singleUse: Scalars['Boolean']['output'];
  slots?: Maybe<Array<Scalars['Int']['output']>>;
  status?: Maybe<OrderStatusEnum>;
  /** TOTAL or PERCENT */
  type: Scalars['String']['output'];
  used: Scalars['Int']['output'];
  user?: Maybe<User>;
  value: Scalars['Float']['output'];
  weekDays: Array<WeekDayEnum>;
};

export type WeekDay = {
  __typename?: 'WeekDay';
  currentTenant: Scalars['String']['output'];
  hourRanges?: Maybe<Array<HourRange>>;
  wday: WeekDayEnum;
};

export enum WeekDayEnum {
  All = 'all',
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Zone = {
  __typename?: 'Zone';
  currentTenant: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  region: Region;
};

export type CurrentFestivalEditionQueryVariables = Exact<{
  code?: InputMaybe<FestivalCodesEnum>;
}>;


export type CurrentFestivalEditionQuery = { __typename?: 'Query', currentFestivalEdition?: { __typename: 'FestivalEdition', id: string, price: number, startsAt: string, endsAt: string, state: FestivalStateEnum, festival: { __typename?: 'Festival', name: string, code: string } } | null, user?: { __typename?: 'User', firstName?: string | null } | null };

export type FerModalFragment = { __typename?: 'FestivalEditionRestaurant', id: string, reservableFrom: string, externalSaleUrl?: string | null, imageFiles: Array<{ __typename?: 'ImageFile', id: string, preview?: string | null }>, menus?: Array<{ __typename?: 'Menu', id?: string | null, label: string, position: number, imageFile?: { __typename?: 'ImageFile', original?: string | null, preview?: string | null } | null, positions: Array<{ __typename?: 'MenuPosition', name: string, content: string }> }> | null, festivalEdition: { __typename?: 'FestivalEdition', id: string, code: string, state: FestivalStateEnum, price: number, forceLabels: boolean, startsAt: string, endsAt: string, reservableFrom: string, slots?: Array<{ __typename?: 'SlotDefinition', slotStart: number }> | null, festival: { __typename?: 'Festival', id: string, code: string, name: string, slogan: string, image?: { __typename?: 'ImageFile', id: string, preview?: string | null } | null } } };

export type FullMenuFragment = { __typename?: 'Menu', id?: string | null, label: string, position: number, imageFile?: { __typename?: 'ImageFile', original?: string | null, preview?: string | null } | null, positions: Array<{ __typename?: 'MenuPosition', name: string, content: string }> };

export type HomepageQueryQueryVariables = Exact<{
  region_id: Scalars['ID']['input'];
  section_slug: Scalars['String']['input'];
}>;


export type HomepageQueryQuery = { __typename?: 'Query', collections: { __typename?: 'Collections', edges?: Array<{ __typename?: 'CollectionEdge', node?: { __typename?: 'PolymorphicCollection', id: string, category: CollectionCategoryEnum, position: number, title?: string | null, restaurants?: Array<{ __typename?: 'Restaurant', id: string, name: string, description?: string | null, slug: string, favorited: boolean, imageFiles: Array<{ __typename?: 'ImageFile', id: string, preview?: string | null }> }> | null, reservableExtras?: Array<{ __typename?: 'ReservableExtra', id: string, name: string, slug: string, price: number, description: string, type: string, restaurant: { __typename?: 'Restaurant', name: string }, image?: { __typename?: 'ImageFile', preview?: string | null } | null }> | null, elements?: Array<{ __typename?: 'CollectionElement', elementId: string, elementType: string }> | null, section: { __typename?: 'Section', slug: string } } | null } | null> | null } };

export type LeanReservableExtraFragment = { __typename?: 'ReservableExtra', id: string, name: string, slug: string, price: number, description: string, enabled: boolean, type: string, reservableFrom: string, image?: { __typename?: 'ImageFile', id: string, preview?: string | null, original?: string | null, thumbnail?: string | null } | null, menus?: Array<{ __typename?: 'Menu', id?: string | null, label: string, position: number, imageFile?: { __typename?: 'ImageFile', original?: string | null, preview?: string | null } | null, positions: Array<{ __typename?: 'MenuPosition', name: string, content: string }> }> | null };

export type NewsletterSignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type NewsletterSignUpMutation = { __typename?: 'Mutation', newsletterSignUp: { __typename?: 'NewsletterSignUp', salesmanagoContactId?: string | null } };

export type ReservableExtraMenusFragment = { __typename?: 'ReservableExtra', menus?: Array<{ __typename?: 'Menu', id?: string | null, label: string, position: number, imageFile?: { __typename?: 'ImageFile', original?: string | null, preview?: string | null } | null, positions: Array<{ __typename?: 'MenuPosition', name: string, content: string }> }> | null };

export type ReservableExtraRulesFragment = { __typename?: 'ReservableExtra', rules: Array<{ __typename?: 'ReservableExtraRule', weekDays?: Array<WeekDayEnum> | null, startsAt: number, startsOn: string, endsAt: number, endsOn?: string | null }> };

export type ReservationOfferFragment = { __typename?: 'ReservableExtra', id: string, name: string, slug: string, price: number, description: string, type: string, image?: { __typename?: 'ImageFile', preview?: string | null } | null };

export type RestaurantInReservableExtraFragment = { __typename?: 'Restaurant', id: string, name: string, slug: string, chef?: string | null, description?: string | null, establishmentYear?: number | null, favorited: boolean, reservables: Array<{ __typename?: 'Daily', id: string, slotDefinitions?: Array<{ __typename?: 'SlotDefinition', possibleSlots: Array<number> }> | null } | { __typename?: 'Event', id: string } | { __typename?: 'FestivalEditionRestaurant', id: string }>, openingHours?: Array<{ __typename?: 'Hours', day: string, from?: string | null, to?: string | null }> | null, tags: Array<{ __typename?: 'Tag', id: string, name: string, type: string, position: number }>, imageFiles: Array<{ __typename?: 'ImageFile', id: string, preview?: string | null }> };

export type SingleReservableExtraQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SingleReservableExtraQuery = { __typename?: 'Query', reservableExtra: { __typename?: 'ReservableExtra', id: string, name: string, slug: string, price: number, description: string, enabled: boolean, type: string, reservableFrom: string, restaurant: { __typename?: 'Restaurant', id: string, name: string, slug: string, chef?: string | null, description?: string | null, establishmentYear?: number | null, favorited: boolean, reservables: Array<{ __typename?: 'Daily', id: string, slotDefinitions?: Array<{ __typename?: 'SlotDefinition', possibleSlots: Array<number> }> | null } | { __typename?: 'Event', id: string } | { __typename?: 'FestivalEditionRestaurant', id: string }>, openingHours?: Array<{ __typename?: 'Hours', day: string, from?: string | null, to?: string | null }> | null, tags: Array<{ __typename?: 'Tag', id: string, name: string, type: string, position: number }>, imageFiles: Array<{ __typename?: 'ImageFile', id: string, preview?: string | null }> }, image?: { __typename?: 'ImageFile', id: string, preview?: string | null, original?: string | null, thumbnail?: string | null } | null, rules: Array<{ __typename?: 'ReservableExtraRule', weekDays?: Array<WeekDayEnum> | null, startsAt: number, startsOn: string, endsAt: number, endsOn?: string | null }>, menus?: Array<{ __typename?: 'Menu', id?: string | null, label: string, position: number, imageFile?: { __typename?: 'ImageFile', original?: string | null, preview?: string | null } | null, positions: Array<{ __typename?: 'MenuPosition', name: string, content: string }> }> | null } };

export type SlotsUsageQueryVariables = Exact<{
  dates: DateRange;
  reservables: Array<ReservableInput> | ReservableInput;
}>;


export type SlotsUsageQuery = { __typename?: 'Query', slots: Array<{ __typename?: 'Slot', date: string, discount: number, runningOut: boolean, sizes: Array<number>, slot: number, interval: number, primeTimeFee?: number | null, reservable?: { __typename?: 'Daily', id: string } | { __typename?: 'Event', id: string } | { __typename?: 'FestivalEditionRestaurant', id: string } | null }> };

export type SponsorsQueryVariables = Exact<{
  festivalEditionId?: InputMaybe<Scalars['ID']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
  clubSponsors?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SponsorsQuery = { __typename?: 'Query', sponsors: { __typename?: 'Sponsors', nodes?: Array<{ __typename?: 'Sponsor', id: string, label?: string | null, kind: SponsorKindsEnum, subkind?: SponsorSubkindsEnum | null, url?: string | null, image?: { __typename?: 'ImageFile', id: string, preview?: string | null } | null } | null> | null } };

export type TagFragmentFragment = { __typename?: 'Tag', id: string, name: string, type: string, position: number };

export const FullMenuFragmentDoc = gql`
    fragment FullMenu on Menu {
  id
  imageFile {
    original
    preview
  }
  label
  position
  positions {
    name
    content
  }
}
    `;
export const FerModalFragmentDoc = gql`
    fragment FERModal on FestivalEditionRestaurant {
  id
  imageFiles {
    id
    preview
  }
  reservableFrom
  menus {
    ...FullMenu
  }
  externalSaleUrl
  festivalEdition {
    id
    code
    state
    price
    slots {
      slotStart
    }
    forceLabels
    startsAt
    endsAt
    reservableFrom
    festival {
      id
      code
      name
      slogan
      image {
        id
        preview
      }
    }
  }
}
    ${FullMenuFragmentDoc}`;
export const ReservableExtraMenusFragmentDoc = gql`
    fragment ReservableExtraMenus on ReservableExtra {
  menus {
    ...FullMenu
  }
}
    ${FullMenuFragmentDoc}`;
export const LeanReservableExtraFragmentDoc = gql`
    fragment LeanReservableExtra on ReservableExtra {
  ...ReservableExtraMenus
  id
  name
  slug
  price
  description
  enabled
  type
  reservableFrom
  image {
    id
    preview
    original
    thumbnail
  }
}
    ${ReservableExtraMenusFragmentDoc}`;
export const ReservableExtraRulesFragmentDoc = gql`
    fragment ReservableExtraRules on ReservableExtra {
  rules {
    weekDays
    startsAt
    startsOn
    endsAt
    endsOn
  }
}
    `;
export const ReservationOfferFragmentDoc = gql`
    fragment ReservationOffer on ReservableExtra {
  id
  name
  slug
  price
  description
  type
  image {
    preview
  }
}
    `;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  type
  position
}
    `;
export const RestaurantInReservableExtraFragmentDoc = gql`
    fragment RestaurantInReservableExtra on Restaurant {
  id
  name
  slug
  chef
  description
  establishmentYear
  favorited
  reservables {
    ... on Daily {
      id
      slotDefinitions {
        possibleSlots
      }
    }
    ... on FestivalEditionRestaurant {
      id
    }
    ... on Event {
      id
    }
  }
  openingHours {
    day
    from
    to
  }
  tags {
    ...TagFragment
  }
  imageFiles {
    id
    preview
  }
}
    ${TagFragmentFragmentDoc}`;
export const CurrentFestivalEditionDocument = gql`
    query currentFestivalEdition($code: FestivalCodesEnum) {
  currentFestivalEdition(code: $code) {
    __typename
    id
    price
    startsAt
    endsAt
    festival {
      name
      code
    }
    state
  }
  user {
    firstName
  }
}
    `;

/**
 * __useCurrentFestivalEditionQuery__
 *
 * To run a query within a React component, call `useCurrentFestivalEditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentFestivalEditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentFestivalEditionQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCurrentFestivalEditionQuery(baseOptions?: Apollo.QueryHookOptions<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>(CurrentFestivalEditionDocument, options);
      }
export function useCurrentFestivalEditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>(CurrentFestivalEditionDocument, options);
        }
export function useCurrentFestivalEditionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>(CurrentFestivalEditionDocument, options);
        }
export type CurrentFestivalEditionQueryHookResult = ReturnType<typeof useCurrentFestivalEditionQuery>;
export type CurrentFestivalEditionLazyQueryHookResult = ReturnType<typeof useCurrentFestivalEditionLazyQuery>;
export type CurrentFestivalEditionSuspenseQueryHookResult = ReturnType<typeof useCurrentFestivalEditionSuspenseQuery>;
export type CurrentFestivalEditionQueryResult = Apollo.QueryResult<CurrentFestivalEditionQuery, CurrentFestivalEditionQueryVariables>;
export const HomepageQueryDocument = gql`
    query HomepageQuery($region_id: ID!, $section_slug: String!) {
  collections(region_id: $region_id, section_slug: $section_slug) {
    edges {
      node {
        id
        category
        position
        title
        restaurants {
          id
          name
          description
          slug
          favorited
          imageFiles {
            id
            preview
          }
        }
        reservableExtras {
          ...ReservationOffer
          restaurant {
            name
          }
        }
        elements {
          elementId
          elementType
        }
        section {
          slug
        }
      }
    }
  }
}
    ${ReservationOfferFragmentDoc}`;

/**
 * __useHomepageQueryQuery__
 *
 * To run a query within a React component, call `useHomepageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageQueryQuery({
 *   variables: {
 *      region_id: // value for 'region_id'
 *      section_slug: // value for 'section_slug'
 *   },
 * });
 */
export function useHomepageQueryQuery(baseOptions: Apollo.QueryHookOptions<HomepageQueryQuery, HomepageQueryQueryVariables> & ({ variables: HomepageQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageQueryQuery, HomepageQueryQueryVariables>(HomepageQueryDocument, options);
      }
export function useHomepageQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageQueryQuery, HomepageQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageQueryQuery, HomepageQueryQueryVariables>(HomepageQueryDocument, options);
        }
export function useHomepageQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomepageQueryQuery, HomepageQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomepageQueryQuery, HomepageQueryQueryVariables>(HomepageQueryDocument, options);
        }
export type HomepageQueryQueryHookResult = ReturnType<typeof useHomepageQueryQuery>;
export type HomepageQueryLazyQueryHookResult = ReturnType<typeof useHomepageQueryLazyQuery>;
export type HomepageQuerySuspenseQueryHookResult = ReturnType<typeof useHomepageQuerySuspenseQuery>;
export type HomepageQueryQueryResult = Apollo.QueryResult<HomepageQueryQuery, HomepageQueryQueryVariables>;
export const NewsletterSignUpDocument = gql`
    mutation NewsletterSignUp($email: String!) {
  newsletterSignUp(email: $email) {
    salesmanagoContactId
  }
}
    `;
export type NewsletterSignUpMutationFn = Apollo.MutationFunction<NewsletterSignUpMutation, NewsletterSignUpMutationVariables>;

/**
 * __useNewsletterSignUpMutation__
 *
 * To run a mutation, you first call `useNewsletterSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterSignUpMutation, { data, loading, error }] = useNewsletterSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useNewsletterSignUpMutation(baseOptions?: Apollo.MutationHookOptions<NewsletterSignUpMutation, NewsletterSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewsletterSignUpMutation, NewsletterSignUpMutationVariables>(NewsletterSignUpDocument, options);
      }
export type NewsletterSignUpMutationHookResult = ReturnType<typeof useNewsletterSignUpMutation>;
export type NewsletterSignUpMutationResult = Apollo.MutationResult<NewsletterSignUpMutation>;
export type NewsletterSignUpMutationOptions = Apollo.BaseMutationOptions<NewsletterSignUpMutation, NewsletterSignUpMutationVariables>;
export const SingleReservableExtraDocument = gql`
    query SingleReservableExtra($id: ID!) {
  reservableExtra(id: $id) {
    ...LeanReservableExtra
    ...ReservableExtraRules
    restaurant {
      ...RestaurantInReservableExtra
    }
  }
}
    ${LeanReservableExtraFragmentDoc}
${ReservableExtraRulesFragmentDoc}
${RestaurantInReservableExtraFragmentDoc}`;

/**
 * __useSingleReservableExtraQuery__
 *
 * To run a query within a React component, call `useSingleReservableExtraQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleReservableExtraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleReservableExtraQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleReservableExtraQuery(baseOptions: Apollo.QueryHookOptions<SingleReservableExtraQuery, SingleReservableExtraQueryVariables> & ({ variables: SingleReservableExtraQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>(SingleReservableExtraDocument, options);
      }
export function useSingleReservableExtraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>(SingleReservableExtraDocument, options);
        }
export function useSingleReservableExtraSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>(SingleReservableExtraDocument, options);
        }
export type SingleReservableExtraQueryHookResult = ReturnType<typeof useSingleReservableExtraQuery>;
export type SingleReservableExtraLazyQueryHookResult = ReturnType<typeof useSingleReservableExtraLazyQuery>;
export type SingleReservableExtraSuspenseQueryHookResult = ReturnType<typeof useSingleReservableExtraSuspenseQuery>;
export type SingleReservableExtraQueryResult = Apollo.QueryResult<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>;
export const SlotsUsageDocument = gql`
    query SlotsUsage($dates: DateRange!, $reservables: [ReservableInput!]!) {
  slots(dates: $dates, reservables: $reservables) {
    date
    discount
    runningOut
    sizes
    slot
    interval
    primeTimeFee
    reservable {
      ... on Daily {
        id
      }
      ... on Event {
        id
      }
      ... on FestivalEditionRestaurant {
        id
      }
    }
  }
}
    `;

/**
 * __useSlotsUsageQuery__
 *
 * To run a query within a React component, call `useSlotsUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlotsUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlotsUsageQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *      reservables: // value for 'reservables'
 *   },
 * });
 */
export function useSlotsUsageQuery(baseOptions: Apollo.QueryHookOptions<SlotsUsageQuery, SlotsUsageQueryVariables> & ({ variables: SlotsUsageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlotsUsageQuery, SlotsUsageQueryVariables>(SlotsUsageDocument, options);
      }
export function useSlotsUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlotsUsageQuery, SlotsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlotsUsageQuery, SlotsUsageQueryVariables>(SlotsUsageDocument, options);
        }
export function useSlotsUsageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SlotsUsageQuery, SlotsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SlotsUsageQuery, SlotsUsageQueryVariables>(SlotsUsageDocument, options);
        }
export type SlotsUsageQueryHookResult = ReturnType<typeof useSlotsUsageQuery>;
export type SlotsUsageLazyQueryHookResult = ReturnType<typeof useSlotsUsageLazyQuery>;
export type SlotsUsageSuspenseQueryHookResult = ReturnType<typeof useSlotsUsageSuspenseQuery>;
export type SlotsUsageQueryResult = Apollo.QueryResult<SlotsUsageQuery, SlotsUsageQueryVariables>;
export const SponsorsDocument = gql`
    query sponsors($festivalEditionId: ID, $regionId: ID, $clubSponsors: Boolean) {
  sponsors(
    festivalEditionId: $festivalEditionId
    regionId: $regionId
    clubSponsors: $clubSponsors
  ) {
    nodes {
      id
      label
      kind
      subkind
      image {
        id
        preview
      }
      url
    }
  }
}
    `;

/**
 * __useSponsorsQuery__
 *
 * To run a query within a React component, call `useSponsorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorsQuery({
 *   variables: {
 *      festivalEditionId: // value for 'festivalEditionId'
 *      regionId: // value for 'regionId'
 *      clubSponsors: // value for 'clubSponsors'
 *   },
 * });
 */
export function useSponsorsQuery(baseOptions?: Apollo.QueryHookOptions<SponsorsQuery, SponsorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument, options);
      }
export function useSponsorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SponsorsQuery, SponsorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument, options);
        }
export function useSponsorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SponsorsQuery, SponsorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument, options);
        }
export type SponsorsQueryHookResult = ReturnType<typeof useSponsorsQuery>;
export type SponsorsLazyQueryHookResult = ReturnType<typeof useSponsorsLazyQuery>;
export type SponsorsSuspenseQueryHookResult = ReturnType<typeof useSponsorsSuspenseQuery>;
export type SponsorsQueryResult = Apollo.QueryResult<SponsorsQuery, SponsorsQueryVariables>;